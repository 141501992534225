import React from "react";
import styles from "./styles.module.css";

const PageHeader = ({ smallHeader, header, paragraph, removePadding }) => {
  return (
    <div className={styles.container} style={removePadding ? { marginLeft: "0px", marginRight: "0px" } : {}}>
      <h1>{smallHeader}</h1>
      <div className={styles["flex-container"]}>
        <div>
          <h2>{header}</h2>
        </div>
        {paragraph ? (
          <div>
            <p>{paragraph}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export { PageHeader };
