import React from "react";

import styles from "./styles.module.css";

import CardImage1 from "./../../../assets/images/codeville/gallery/_junior/image-1.png";
import CardImage2 from "./../../../assets/images/codeville/gallery/_junior/image-2.png";
import CardImage3 from "./../../../assets/images/codeville/gallery/_junior/image-3.png";
import WorkFunImage from "./../../../assets/images/codeville/gallery/_junior/work-fun.png";

import { FunActivities, GallerySection, LearningActivities } from "./widgets";

const CodeVilleKidsCodeCampGalleryPage = () => {

	return (<main>
		<section className={styles.vanguard}>
			<div className={styles.vanguard_header}>
				<h1>Kids Code Camp</h1>
				<p>With Codeville, African kids can be placed several steps ahead in the world&apos;s race for knowledge, positive growth, and technology breakthrough. </p>
			</div>
			<div className={styles.vanguard_content}>
				<div className={styles.vanguard_contentWrapper}>
					<div className={styles.vanguard_contentWrapper_firstCard}>
						<div>
							<img src={CardImage1} />
						</div>
						<div>
							<img src={CardImage2} />
						</div>
						<div>
							<img src={CardImage3} />
						</div>
					</div>
					<div className={styles.vanguard_contentWrapper_secondCard}>
						<div className={styles.vanguard_contentWrapper_secondCard_content}>
							<span>We don&apos;t just work, we have fun!</span>
							<p>Have a quick view of our gallery to have a feel of what we have been up to</p>
						</div>
						<div className={styles.vanguard_contentWrapper_secondCard_imageContainer}>
							<img src={WorkFunImage} />
						</div>
					</div>
				</div>
			</div>
		</section>
		<GallerySection></GallerySection>
		<LearningActivities></LearningActivities>
		<FunActivities></FunActivities>
	</main>)
}

export { CodeVilleKidsCodeCampGalleryPage };