import React, { useState } from "react";
import { Minus, Plus } from "../../assets";

import styles from "./styles.module.css";

const FaqCard = ({ header, paragraph }) => {
  const [showParagraph, setShowParagraph] = useState(false);
  return (
    <div className={styles.container} onClick={() => setShowParagraph(!showParagraph)}>
      <h1>{header}</h1>
      {showParagraph ? <p>{paragraph}</p> : ""}
      {!showParagraph ? <Plus /> : <Minus />}
    </div>
  );
};

export { FaqCard };
