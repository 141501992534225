import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../../assets/svg/codeville";
import { SHEET_DB_URL } from "../../../config";
import { Toast } from "../../toast";
import { CodeVilleButton } from "../button";
import { CodeVilleInput } from "../input";
import styles from "./styles.module.css";

const CodeVilleFooter = () => {
  const [email, setEmail] = useState("");
  const [displayToast, setDisplayToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const subscribeToMailList = async (e) => {
    e.preventDefault();
    setLoad(true);
    fetch(SHEET_DB_URL, {
      method: "POST",
      body: JSON.stringify({ json: { email }, sheet: "Mailing List" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response?.status >= 400) {
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        } else {
          setEmail("");
          setToastDetails({ toastState: "success", message: "You have joined our mailing list!" });
          setDisplayToast(true);
        }
      })
      .catch(() => {
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      })
      .finally(() => setLoad(false));
  };

  const FOOTER_LINKS = useMemo(
    () => ({
      "Useful Links": [
        {
          title: "About",
          link: "/codeville/about",
        },
        {
          title: "Contact us",
          link: "/codeville/contact",
        },
        {
          title: "FAQs",
          link: "/codeville/faqs",
        },
      ],
      Programmes: [
        {
          title: "Codeville Kids Code Camp",
          link: "/codeville/programmes/junior",
        },
        {
          title: "Codeville Middle Class",
          link: "/codeville/programmes/middle-class",
        },
        {
          title: "Codeville Junior Academy",
          link: "/codeville/programmes/junior-waec",
        },
      ],
    }),
    [],
  );

  // Current date
  const currentYear = new Date().toDateString().split(" ").splice(-1);

  return (
    <footer className={styles.footerContainer}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}
      <section className={styles.footerInfoAndLinksContainer}>
        <div className={styles.footerInfoContainer}>
          <h2>Stay Connected</h2>
          <p>Join our mailing list to receive information about our work and new learning opportunities.</p>
          <form onSubmit={subscribeToMailList}>
            <aside className={styles.infoSubscribeContainer}>
              <CodeVilleInput
                placeholder="Enter Your Email"
                name="email"
                type="email"
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                required
                customClass={styles.subscribeInput}
              />
              <CodeVilleButton label={load ? "Loading..." : "Subscribe"} buttonType="primary" disabled={load} />
            </aside>
          </form>
        </div>
        <div className={styles.footerLinksAndContactContainer}>
          <aside className={styles.footerLinksContainer}>
            {Object.keys(FOOTER_LINKS).map((linkName, idx) => (
              <div key={linkName + idx} className={styles.footerLinksGroupContainer}>
                <h3>{linkName}</h3>
                <ul className={styles.footerLinksGroup}>
                  {FOOTER_LINKS[linkName].map((link) => (
                    <li key={link.title} className={styles.footerLinkItem}>
                      <Link to={link.link}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </aside>
          <aside className={styles.getInTouch}>
            <h3>Get in Touch</h3>
            <p>Our Local Campus center: Kilometer 7, Enugu/Port Harcourt, Expressway, Centenary City, Enugu. Nigeria</p>
          </aside>
        </div>
      </section>
      <section className={styles.footerSocialsContainer}>
        <div className={styles.footerSocialsWrapper}>
          <aside className={styles.footerCopy}>
            <p>&copy; Genesys {currentYear}</p>
            <p>POWERED BY HUBLY</p>
          </aside>

          <aside className={styles.socialLinksContainer}>
            <a href="http://">
              <TwitterIcon />
            </a>
            <a href="http://">
              <FacebookIcon />
            </a>
            <a href="http://">
              <LinkedInIcon />
            </a>
            <a href="http://">
              <InstagramIcon />
            </a>
            <a href="http://">
              <YoutubeIcon />
            </a>
            <a href="http://">
              <TikTokIcon />
            </a>
          </aside>
        </div>
      </section>
    </footer>
  );
};

export { CodeVilleFooter };
