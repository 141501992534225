import React from "react";

import styles from "./styles.module.css";

const Stepper = ({ steps }) => {
  return (
    <div className={styles.container}>
      {steps.map((step, index) => {
        return (
          <div key={`${step?.header}+${index}`} className={styles.step}>
            <div className={`${styles["step-circle"]} ${index !== steps.length - 1 ? styles["dotted"] : ""}`}>
              <h1>{index + 1}</h1>
            </div>
            <div className={styles["text-container"]}>
              <h2>{step?.header}</h2>
              <p>{step?.paragraph}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { Stepper };
