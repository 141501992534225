import { funActivitiesImage, TickIcon } from "../../../../assets";
import { CodeVilleBasicCard, CodeVilleProgramCard } from "../../../../components";
import styles from "./styles.module.css";
import React from "react";
import { CODEVILLE_APP_LINKS } from "../../../../config";

const FunTeachExperienceSection = () => {
  return (
    <div className={styles.mainContainer}>
      <section className={`codevilleSectionWrapper ${styles.teachSection}`}>
        <aside className={styles.teachSectionContent}>
          <div className={styles.teachSectionContentHeader}>
            <h6>How WE do it?</h6>
            <h2>We Teach Children</h2>
          </div>
          <p>
            The Codeville curriculum gives your child the opportunity to get introduced to 4 of the most relevant
            concepts used by engineering teams all over the world today.
          </p>
        </aside>

        <aside className={styles.teachCardContainer}>
          <CodeVilleBasicCard
            customClassName={styles.teachCardItem}
            description="The basics to the science behind how robots work."
            title="Robotics"
          />
          <CodeVilleBasicCard
            customClassName={styles.teachCardItem}
            description="All there is to know about the internet, websites, and how they work. "
            title="Web Design"
          />
          <CodeVilleBasicCard
            customClassName={styles.teachCardItem}
            description="Working with creative design tools  for creating visually appealing output. "
            title="Visual Design"
          />
          <CodeVilleBasicCard
            customClassName={styles.teachCardItem}
            description="Futuristic technology; introductions to Artificial Intelligence and Mixed Reality."
            title="Introduction to Emerging Technologies"
          />
          <CodeVilleBasicCard
            customClassName={styles.teachCardItem}
            description="The building blocks on how programming languages can be used to build simple solutions."
            title="Programming"
          />
        </aside>
      </section>
      <section className={styles.experienceSection}>
        <aside className={styles.experienceSectionHeader}>
          <h6>Our Programs</h6>
          <h2>The Codeville Experience</h2>
        </aside>
        <aside className={styles.experienceProgramCardContainer}>
          <CodeVilleProgramCard
            customClassName={styles.experienceProgramCard}
            applyLink={CODEVILLE_APP_LINKS.kidscodeCamp}
            programType="entry"
          />
          <CodeVilleProgramCard
            customClassName={styles.experienceProgramCard}
            applyLink={CODEVILLE_APP_LINKS.middleClass}
            programType="junior"
          />
          <CodeVilleProgramCard
            customClassName={styles.experienceProgramCard}
            applyLink={CODEVILLE_APP_LINKS.juniorAcademy}
            programType="advanced"
          />
          <CodeVilleProgramCard
            customClassName={styles.experienceProgramCard}
            applyLink={CODEVILLE_APP_LINKS.juniorAcademyVirtual}
            programType="advancedVirtual"
          />
        </aside>
      </section>

      <section className={`codevilleSectionWrapper ${styles.funActivitiesSection}`}>
        <aside className={styles.funActivitiesSectionHeader}>
          <h6>Not just about</h6>
          <h2>Other Fun Activities</h2>
          <p>
            It’s not all just about coding at codeville. There are other activities your child participates in during
            the course of the programme.{" "}
          </p>
          <ul>
            <li>
              <TickIcon /> Movies and Games
            </li>
            <li>
              <TickIcon /> Entrepreneurship Classes
            </li>
            <li>
              <TickIcon /> Tech Talk Thursdays
            </li>
          </ul>
        </aside>
        <aside className={styles.funActivitiesSectionImage}>
          <img src={funActivitiesImage} alt="student having fun" />
        </aside>
      </section>
    </div>
  );
};

export { FunTeachExperienceSection };
