import React from "react";
import styles from "./styles.module.css";

// Text Area Inputs
const CodeVilleInput = ({
  type = "text",
  placeholder = "placeholder",
  customStyles = {},
  customClass = "",
  onChange = () => {},
  ...rest
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      style={customStyles}
      className={`${styles.inputContainer} ${customClass}`}
      {...rest}
    />
  );
};

// Text Area Component
const CodeVilleTextArea = ({
  placeholder = "placeholder",
  customStyles = {},
  customClass = "",
  onChange = () => {},
  ...rest
}) => {
  return (
    <textarea
      placeholder={placeholder}
      onChange={onChange}
      style={customStyles}
      className={`${styles.inputContainer} ${styles.textareaInput} ${customClass}`}
      {...rest}
    />
  );
};

export { CodeVilleInput, CodeVilleTextArea };
