import { NewGround1, NewGround2, NewGround3, NewGround4, NewGround5, NewGround6 } from "../../../assets";

export * from "./newGroundsCard";

export const newGroundsData = [
  {
    Icon: NewGround1,
    header: "Unfiltered Mentorship",
    paragraph:
      "Enjoy close guidance and mentorship from established CTOs, and Tech Leads working for global companies.",
  },
  {
    Icon: NewGround2,
    header: "Project Based Learning",
    paragraph:
      "At Startup Boost, we apply a learning approach where business learn and grow by carrying out real-life projects.",
  },
  {
    Icon: NewGround3,
    header: "No Fees",
    paragraph:
      "All the knowledge, resources, and access offered by Startup Boost is up for grabs at zero cost. That’s right... It’s free!",
  },
  {
    Icon: NewGround4,
    header: "Access To Learning Resources",
    paragraph: "Everything you need to excel at Startup Boost will be within good reach of you. ",
  },
  {
    Icon: NewGround5,
    header: "Easy Onboarding",
    paragraph: "Commencing your Startup Boost journey is a stress-free process, as easy as can be.",
  },
  {
    Icon: NewGround6,
    header: "Practical Methods",
    paragraph:
      "Our learning methods encourage you to do more. Applying practical methods to your business and watching the results unfold.",
  },
];
