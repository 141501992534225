import React from 'react';

import styles from './styles.module.css';

const FullGridCard   = ({className, smallHeading, largeHeading, firstParagraph, secondParagraph}) => {
    return (
        <div className={styles.containerFluid}>
            <div className={styles.col}>
                <div className={styles.headingWrapper}>
                    <h6 className={`${styles.smallHeading} ${className}`}>{smallHeading}</h6>
                    <h4 className={`${styles.largeHeading} ${className}`}>{largeHeading}</h4>
                </div>
                <div className={styles.paragraphWrapper}>
                    <p className={`${styles.fullGridCardParagraph1} ${className}`}>{firstParagraph}</p>
                    <p className={`${styles.fullGridCardParagraph2} ${className}`}>{secondParagraph}</p>
                </div>
            </div>
        </div>
    )
}
export { FullGridCard };