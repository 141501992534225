import React, { useMemo, useState } from "react";
import { RightArrowIcon, progJunAbtImage } from "../../../assets";
import { CODEVILLE_APP_LINKS } from "../../../config";
import { CodeVilleProgramLayout } from "../../../layouts";
import webDev from "./web-dev.json";
import gameDev from "./game-dev.json";
import styles from "./styles.module.css";

const CodeVilleJuniorPage = () => {
  // Page FAQs
  const programFAQs = useMemo(
    () => [
      {
        accordionQuestion: "Who is eligible for this?",
        questionAnswer:
          "This is a post junior WAEC class and is suitable for every child who will be participating in the junior WAEC/ NECO exams",
      },
      {
        accordionQuestion: "What are the requirements?",
        questionAnswer: ["Must  be on junior WAEC break", "Must own a laptop"],
      },
      {
        accordionQuestion: "Does the child need any IT skills to enroll?",
        questionAnswer: "To participate, The child should be able to boot a computer system",
      },
      {
        accordionQuestion: "Can the Codeville program inform my career choice?",
        questionAnswer:
          "Yes! We believe that the junior WAEC phase is a critical time of a child’s life and and a major decision making period. This program will guide especially every child who wants to take a career in sciences as well as children who would like to acquire a new skill during the long JSS3 break",
      },
      {
        accordionQuestion: "What is next after the program?",
        questionAnswer:
          "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you’ll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You’ll also have free access to any of our kiddies tech events.",
      },
      {
        accordionQuestion: "Can I reach out to anyone for further information?",
        questionAnswer:
          "Yes, please call 07037153284 Or 08124487288 or email codeville@genesystechhb.com.",
      },
    ],
    [],
  );

  // const programs details
  const program_data = {
    "web-dev": webDev,
    "game-dev": gameDev,
  }
  const [programData, setProgramData] = useState({
    program: "web-dev",
    data: webDev,
  });

  function fetchProgram(program){
    const data = program_data[program];
		if (data) {
			// console.log(data);
			setProgramData({
				program,
				data,
			});
		} else {
			setProgramData({
				program,
				data: null,
			});
		}
  }

  return (
    <CodeVilleProgramLayout
      applyLink={CODEVILLE_APP_LINKS.juniorAcademy || CODEVILLE_APP_LINKS.generalLink}
      programTitle="Codeville Junior Academy"
      programDesc="In the race against time, we have the opportunity to let our kids kickoff their journeys as early as possible. Setting the right foundations for successful careers in technology and engineering. "
      programFAQs={programFAQs}
      porgramAboutImage={progJunAbtImage}
      aboutProgSubTitle="The smart ones have been born"
      programDetails={programData.data}
      tabElement={
        <div className={styles.content_tabs}>
					<span
						className={(programData.program === "web-dev") && styles.content_tabs_activeTab}
						onClick={() => fetchProgram("web-dev")}
					>Web Dev</span>
					<span
						className={(programData.program === "game-dev") && styles.content_tabs_activeTab}
						onClick={() => fetchProgram("game-dev")}
					>Game Dev</span>
				</div>
      }
      bannerElement={
        <div className={styles.bannerContainer}>
          <div className={styles.bannerWrapper}>
            <div className={styles.bannerBody}>
              <div className={styles.bannerBody_wrapper}>
              <h4>Get Onboard</h4>
              <p>Ready to level up your game development skills? Enroll now and start building the games of your dreams!</p>
              <a target="_blank" href="/codeville/closed-app">
                <span>Apply Now</span>
                  <RightArrowIcon/>
              </a>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export { CodeVilleJuniorPage };
