import React, { useMemo } from "react";
import { progJunWAbtImage } from "../../../assets";
import { CODEVILLE_APP_LINKS } from "../../../config";
import { CodeVilleProgramLayout } from "../../../layouts";
import kidsCodeProgram from "./kids-code-camp.json";

const CodeVilleKidsCodeCamp = () => {
  // Page FAQs
  const programFAQs = useMemo(
    () => [
      
      {
        accordionQuestion: "Who is eligible for the Codeville Kids Code Camp?",
        questionAnswer:
          "This program suits any child between the ages of 9 and 13, interested in technology and  would like to be introduced to computer programming and robotics",
      },
      {
        accordionQuestion: "What are the requirements?",
        questionAnswer: ["Must be between the ages of 9 and 13", "Must own a laptop"],
      },
      {
        accordionQuestion: "Does the child need any IT skills to enroll?",
        questionAnswer: "No, this is an introductory class, so the child doesn't need any IT skill to participate",
      },
      {
        accordionQuestion: "Can the Codeville program inform my career choice?",
        questionAnswer:
          "Yes! this program is designed to introduce the child to the beautiful world of technology and inventions and tailored to narrow what aspect of technology the child is most interested in",
      },
      {
        accordionQuestion: "What is next after the program?",
        questionAnswer:
          "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you'll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You'll also have free access to any of our kiddies tech events.",
      },
      {
        accordionQuestion: "Can I reach out to anyone for further information?",
        questionAnswer:
          "Yes, please call 07037153284 Or 08124487288 or email codeville@genesystechhb.com.",
      },
    ],
    [],
  );

  return (
    <CodeVilleProgramLayout
      programFAQs={programFAQs}
      programTitle="Codeville Kids Code Camp"
      programDesc="With Codeville, African kids can be placed several steps ahead in the world’s race for knowledge, positive growth, and technology breakthrough. "
      porgramAboutImage={progJunWAbtImage}
      programDetails={kidsCodeProgram}
      aboutProgSubTitle="Ready... set... Learn"
      applyLink={CODEVILLE_APP_LINKS.kidscodeCamp || CODEVILLE_APP_LINKS.generalLink}
    />
  );
};

export { CodeVilleKidsCodeCamp };
