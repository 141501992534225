import React, { useMemo } from "react";
import { progMidAbtImage } from "../../../assets";
import { CODEVILLE_APP_LINKS } from "../../../config";
import { CodeVilleProgramLayout } from "../../../layouts";
import middleClassProgram from "./middle-class.json";

const CodeVilleMiddleClassPage = () => {
  // Page FAQs
  const programFAQs = useMemo(
    () => [
      {
        accordionQuestion: "Who is eligible for the Codeville Middle Class?",
        questionAnswer:
          "Codeville Middle Class is a continuation of the Codeville introductory class. And designed to move the child from a beginners level  to an intermediary level of programing.Therefore it is suitable for any child who has participated in the Codeville Kids Code Camp.",
      },
      {
        accordionQuestion: "What are the requirements?",
        questionAnswer: [
          "Must  have participated in the Codeville Kids Code Camp",
          "Available weekdays in August",
          "Must own a laptop",
        ],
      },
      {
        accordionQuestion: "Does the child need any IT skills to enroll?",
        questionAnswer: "To participate, The child should be able to boot a computer system",
      },
      {
        accordionQuestion: "Can the Codeville program inform my career choice?",
        questionAnswer:
          "Yes! this program is designed to introduce the child to the beautiful world of technology and inventions and tailored to narrow what aspect of technology the child is most interested in.",
      },
      {
        accordionQuestion: "What is next after the program?",
        questionAnswer:
          "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you’ll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You’ll also have free access to any of our kiddies tech events.",
      },
      {
        accordionQuestion: "Can I reach out to anyone for further information?",
        questionAnswer:
          "Yes, please call 07037153284 Or 08124487288 or email codeville@genesystechhb.com.",
      },
    ],
    [],
  );

  return (
    <CodeVilleProgramLayout
      programFAQs={programFAQs}
      programTitle="Codeville Middle Class"
      programDesc="Our kids can truly be leaders of the future if they start learning the right things today. We are on a mission to help as many kids learn everything they need to be tech superheroes."
      porgramAboutImage={progMidAbtImage}
      programDetails={middleClassProgram}
      aboutProgramText={
        <React.Fragment>
          <p>
            The Codeville Middle Class is specially designed for children who have previously concluded the Codeville
            Junior program. Here, our kids will get even more hands-on while learning advanced concepts in Software
            Development, Design, Artificial Intelligence, and more. At the end of this experience, our kids come out
            with the ability to engineer tech-enabled solutions to real world problems.
          </p>
        </React.Fragment>
      }
      aboutProgSubTitle="Consistency breeds perfection"
      applyLink={CODEVILLE_APP_LINKS.middleClass || CODEVILLE_APP_LINKS.generalLink}
    />
  );
};

export { CodeVilleMiddleClassPage };
