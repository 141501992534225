import React from "react";

import styles from "./styles.module.css";

const Textarea = ({ className, type, placeholder, rows, cols, id, onChange, ...rest }) => {
  return (
    <div className={styles.textareaWrapper}>
      <textarea
        className={`${styles.textarea} ${className}`}
        type={type}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        id={id}
        onChange={onChange}
        {...rest}
      ></textarea>
    </div>
  );
};
export { Textarea };
