import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Core modules imports are same as usual
import { Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import learningImages from "./codeville-learning-images.json";
import { CardsIcon, CodevilleIcon, LeftArrowIcon, RightArrowIcon } from "../../../../../assets/svg/codeville";

const LearningActivities = () => {
  const mySwiper = useRef(null);

  const [activeSlide, setActiveSlide] = useState(1);

  return (
    <section className={styles.learningActivities}>
      <div className={styles.learningActivities_header}>
        <span>Learning Activities</span>
        <h1>We have the best learning processes</h1>
      </div>
      <div className={styles.learningActivities_content}>
        <Swiper
          onBeforeInit={(swiper) => (mySwiper.current = swiper)}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          className="mySwiper"
          onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex + 1)}
        >
          {learningImages.map(
            (learningSlide, index) =>
              index < 6 && (
                <SwiperSlide key={index} className={styles.slideItem}>
                  <div className={styles.slideItem_container}>
                    <CodevilleIcon className={styles.slideIcon}/>
                    <div className={styles.slideItem_imageContainer}>
                      <img src={learningSlide.image}/>
                    </div>
                    <div className={styles.slideItem_textContainer}>
                      <p>
                        {learningSlide.overview}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ),
          )}
        </Swiper>
        <div className={styles.slide_footer}>
          <div className={styles.slideCards}>
            <span>
              <CardsIcon />
            </span>
            <span>{activeSlide}/6</span>
          </div>
          <div className={styles.slideButtons}>
            <button onClick={() => mySwiper.current?.slidePrev()} disabled={mySwiper.current?.isBeginning}>
              <LeftArrowIcon />
            </button>
            <button onClick={() => mySwiper.current?.slideNext()} disabled={mySwiper.current?.isEnd}>
              <RightArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export { LearningActivities };
