import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";

import funActivitiesImages from "../../../../../assets/data/fun-activities.json";

const FunActivities = () => {
  const [funImage1, setFunImage1] = useState(0);
  const [funImage2, setFunImage2] = useState(0);
  const [funImage3, setFunImage3] = useState(0);

  const funActivities_largeImages_first = funActivitiesImages[0];

  const funActivities_largeImages_second = funActivitiesImages[1];

  const funActivities_images = funActivitiesImages[2];

  useEffect(() => {
    const imageSection1 = setInterval(() => {
      setFunImage1((prevState) => (prevState < funActivities_largeImages_first.length ? prevState + 1 : 0));
    }, 5000);

    const imageSection2 = setInterval(() => {
      setFunImage2((prevState) => (prevState < funActivities_largeImages_second.length ? prevState + 1 : 0));
    }, 4000);

    const imageSection3 = setInterval(() => {
      setFunImage3((prevState) => (prevState < funActivities_images.length ? prevState + 1 : 0));
    }, 6000);

    return () => {
      clearInterval(imageSection1);
      clearInterval(imageSection2);
      clearInterval(imageSection3);
    };
  }, []);

  return (
    <section className={styles.funActivities}>
      <div className={styles.funActivities_header}>
        <span>Fun Activities</span>
        <h1>Other Fun Activities</h1>
        <p>
          It&apos;s not all about the tech at Codeville. There are other activities your child gets to participate in
          during the course of the programme.
        </p>
      </div>
      <div className={styles.funActivities_content}>
        <div className={styles.funActivities_contentWrapper}>
          <div className={styles.funActivities_imageContainerLarge}>
            {funActivities_largeImages_first.map((image, index) => (
              <div
                key={index}
                className={styles.funActivities_imageWrapper}
                style={funImage1 === index ? { opacity: 1 } : {}}
              >
                <img src={image} />
              </div>
            ))}
            <span>Image is loading...</span>
          </div>

          <div className={styles.funActivities_firstCard}>
            <p>
              Studies show that adult-organized classroom games can enhance overall student motivation, leading to
              increased engagement and participation.
            </p>
          </div>

          <div className={styles.funActivities_imageContainer}>
            {funActivities_images.map((image, index) => (
              <div
                key={index}
                className={styles.funActivities_imageWrapper}
                style={funImage2 === index ? { opacity: 1 } : {}}
              >
                <img src={image} />
              </div>
            ))}
            <span>Image is loading...</span>
          </div>

          <div className={styles.funActivities_secondCard}>
            {/* <h2></h2> */}
            <p>
              Games in the classroom allow students to learn how to work together as a team, take turns, build respect,
              listen to others, and play fairly.
            </p>
          </div>

          <div className={`${styles.funActivities_imageContainerLarge} ${styles.funActivities_imageContainerLarge_2}`}>
            {funActivities_largeImages_second.map((image, index) => (
              <div
                key={index}
                className={styles.funActivities_imageWrapper}
                style={funImage3 === index ? { opacity: 1 } : {}}
              >
                <img src={image} />
              </div>
            ))}
            <span>Image is loading...</span>
          </div>

          <div className={styles.funActivities_thirdCard}>
            <p>
              According to a recent study, 91% of learners value fun in learning. It’s also effective. Gamification can
              improve classroom productivity by up to 50%.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export { FunActivities };
