import React from "react";
import { CodeVilleReviewCard } from "../../../../components";
import styles from "./styles.module.css";

const KidsFeedbackSection = () => {
  return (
    <section className={`codevilleSectionWrapper ${styles.feedbackContainer}`}>
      <h6>Testimonials</h6>
      <h2>The Kids Think We’re Awesome</h2>

      <aside className={styles.feedbackCard}>
        <CodeVilleReviewCard />
      </aside>
    </section>
  );
};

export { KidsFeedbackSection };
