import React from 'react'
import styles from '../../styles.module.css';

export const ProfileCard = ({img, name, role}) => {
  return (
    <div className={styles.gallery2Col2}>
      <img src={img} alt="profile_img" className={styles.imgProfile} />
      <div className={styles.gallery2Text2}>
        <h6>{name}</h6>
        <p className={styles.gallery2Paragraph}>{role}</p>
      </div>
    </div>
  )
}

