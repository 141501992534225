import React, { useMemo, useState } from "react";
import { CodeVilleAccordion } from "../../components";
import { usePageResize } from "../../hooks";
import styles from "./styles.module.css";
import { CodeVilleFAQTab } from "./widgets";

const CodeVilleFAQPage = () => {
  const { windowSize } = usePageResize();
  const isSmallerScreen = windowSize < 680;

  // Selected Tab Item
  const [selectedTab, setSelectedTab] = useState("junior");

  // FAQ Tab items
  const FAQTabItems = useMemo(
    () => ({
      junior: {
        title: isSmallerScreen ? "Junior" : "Codeville Kids Code Camp",
        faqItems: [
          {
            accordionQuestion: "Who is eligible for the Codeville Kids Code Camp?",
            questionAnswer:
              "This program suits any child between the ages of 9 and 13, interested in technology and  would like to be introduced to computer programming and robotics",
          },
          {
            accordionQuestion: "What are the requirements?",
            questionAnswer: ["Must be between the ages of 9 and 13", "Must own a laptop"],
          },
          {
            accordionQuestion: "Where is the facility?",
            questionAnswer: "Kilometer 7 Enugu/PH express road Centenary City Enugu",
          },
          {
            accordionQuestion: "When does the program start?",
            questionAnswer: "Program runs through the long vacation (Weekdays in August)",
          },
          {
            accordionQuestion: "What time does classes start?",
            questionAnswer: "10am-3pm",
          },
          {
            accordionQuestion: "Can the Codeville program inform my career choice?",
            questionAnswer:
              "Yes! this program is designed to introduce the child to the beautiful world of technology and inventions and tailored to narrow what aspect of technology the child is most interested in",
          },
          {
            accordionQuestion: "What is next after the program?",
            questionAnswer:
              "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you’ll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You’ll also have free access to any of our kiddies tech events.",
          },
          {
            accordionQuestion: "Does the child need any IT skills to enroll?",
            questionAnswer: "No, this is an introductory class, so the child doesn't need any IT skill to participate",
          },
          {
            accordionQuestion: "Do the children get to build robots?",
            questionAnswer:
              "Yes! Every participant will own a personal robot and will be thought how to assemble and program the robots from scratch to finish",
          },
          {
            accordionQuestion: "What other things will the child learn?",
            questionAnswer:
              "Apart from programming a robot, the child will be privileged to participate in  an introduction to web development class, entrepreneurship class, character building class, interpersonal skill development class and other activities to build and encourage the creativity and leadership abilities of the child ",
          },
          {
            accordionQuestion: "How do we join?",
            questionAnswer:
              "To be part of the Codeville program, please visit the link…., complete the application form, make payment to Wema Bank and send an evidence of payment to c.uduma@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I reach out to anyone for further information?",
            questionAnswer:
              "Yes, please call 07037153284 Or 08124487288 or email c.uduma@genesystechhb.com. a.onyeji@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I make payments in installments?",
            questionAnswer: [
              "Yes you can! Payments can be made in two installments",
              "First installment - Before the application deadline",
              "Second installment - Two weeks before the program completion",
            ],
          },
          {
            accordionQuestion: "Other payment options?",
            questionAnswer:
              "We have made available an option to learn without having to break the bank. With this option, your child will learn how to assemble the robotic kit and all the knowledge that comes with the kit but will not own a kit. The charge of the robotic kit will be subtracted from the program's fee.",
          },
        ],
      },
      middle: {
        title: isSmallerScreen ? "MiddleClass" : "Codeville MiddleClass",
        faqItems: [
          {
            accordionQuestion: "Who is eligible for the Codeville Middle Class?",
            questionAnswer:
              "Codeville Middle Class is a continuation of the Codeville introductory class. And designed to move the child from a beginners level  to an intermediary level of programing.Therefore it is suitable for any child who has participated in the Codeville Kids Code Camp.",
          },
          {
            accordionQuestion: "What are the requirements?",
            questionAnswer: [
              "Must  have participated in the Codeville Kids Code Camp",
              "Must own a laptop",
              "Available weekdays in August",
            ],
          },
          {
            accordionQuestion: "Where is the facility?",
            questionAnswer: "Kilometer 7 Enugu/PH express road Centenary City Enugu",
          },
          {
            accordionQuestion: "When does the program start?",
            questionAnswer: "Program runs through the long vacation (Weekdays in August)",
          },
          {
            accordionQuestion: "What time does classes start?",
            questionAnswer: "10am - 3pm",
          },
          {
            accordionQuestion: "Can the Codeville program inform my career choice?",
            questionAnswer:
              "Yes! this program is designed to introduce the child to the beautiful world of technology and inventions and tailored to narrow what aspect of technology the child is most interested in.",
          },
          {
            accordionQuestion: "What is next after the program?",
            questionAnswer:
              "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you’ll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You’ll also have free access to any of our kiddies tech events.",
          },
          {
            accordionQuestion: "Does the child need any IT skills to enroll?",
            questionAnswer: "Yes, the child needs an introductory level of computer programming to participate.",
          },

          {
            accordionQuestion: "What other things will the child learn?",
            questionAnswer:
              "Apart from programming class, the child will be privileged to participate in  an entrepreneurship class, character building class, interpersonal skill development class and other activities to build and encourage the creativity and leadership abilities of the child.",
          },
          {
            accordionQuestion: "How do we join?",
            questionAnswer:
              "To be part of the Codeville program, please visit the link…., complete the application form, make payment to Wema Bank and send an evidence of payment to c.uduma@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I reach out to anyone for further information?",
            questionAnswer:
              "Yes, please call 07037153284 Or 08124487288 or email c.uduma@genesystechhb.com. a.onyeji@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I make payments in installments?",
            questionAnswer: [
              "Yes you can! Payments can be made in two installments",
              "First installment - Before the application deadline",
              "Second installment - Two weeks before the program completion",
            ],
          },
        ],
      },

      juniorAcademy: {
        title: isSmallerScreen ? "Junior WAEC" : "Codeville Junior Academy",
        faqItems: [
          {
            accordionQuestion: "Who is eligible for this?",
            questionAnswer:
              "This is a post junior WAEC class and is suitable for every child who will be participating in the 2022 junior WAEC/ NECO exams",
          },
          {
            accordionQuestion: "What are the requirements?",
            questionAnswer: ["Must  be on junior WAEC break", "Must own a laptop"],
          },
          {
            accordionQuestion: "Where is the facility?",
            questionAnswer: "Kilometer 7 Enugu/PH express road Centenary City Enugu",
          },
          {
            accordionQuestion: "When does the program start?",
            questionAnswer: "Program runs through the long vacation (Weekdays in August)",
          },
          {
            accordionQuestion: "What time does classes start?",
            questionAnswer: "10am - 3pm",
          },
          {
            accordionQuestion: "Can the Codeville program inform my career choice?",
            questionAnswer:
              "Yes! We believe that the junior WAEC phase is a critical time of a child’s life and and a major decision making period. This program will guide especially every child who wants to take a career in sciences as well as children who would like to acquire a new skill during the long JSS3 break",
          },
          {
            accordionQuestion: "What is next after the program?",
            questionAnswer:
              "After the program, you have a 1 year free access to the hub. This is part of our plans to encourage learning as you’ll be able to use our learning facilities and meet with mentors to assist you with your personal tech-projects. You’ll also have free access to any of our kiddies tech events.",
          },
          {
            accordionQuestion: "Does the child need any IT skills to enroll?",
            questionAnswer: "To participate, The child should be able to boot a computer system.",
          },

          {
            accordionQuestion: "What other things will the child learn?",
            questionAnswer:
              "Here, the child is exposed to 3 fields of technology(Coding, Graphics and Gaming). The program further drills and coaches the child to an expert level of his/her most preferred field",
          },
          {
            accordionQuestion: "How do we join?",
            questionAnswer:
              "To be part of the Codeville program, please visit the link…., complete the application form, make payment to Wema Bank and send an evidence of payment to c.uduma@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I reach out to anyone for further information?",
            questionAnswer:
              "Yes, please call 07037153284 Or 08124487288 or email c.uduma@genesystechhb.com. a.onyeji@genesystechhub.com",
          },
          {
            accordionQuestion: "Can I make payments in installments?",
            questionAnswer: [
              "Yes you can! Payments can be made in two installments",
              "First installment - Before the application deadline",
              "Second installment - Two weeks before the program completion",
            ],
          },
        ],
      },
    }),
    [isSmallerScreen],
  );

  return (
    <main className={styles.faqMainContainer}>
      <header className={styles.faqHeader}>
        <h6>FAQs</h6>
        <h1>Need More Help?</h1>
        <p>Find out all you need to know about Codeville and it’s programmes.</p>
      </header>

      <CodeVilleFAQTab FAQTabItems={FAQTabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <section className={styles.faqAccordionsContainer}>
        <h4>{FAQTabItems[selectedTab].title}</h4>

        <aside className={styles.faqItemsContainer}>
          {FAQTabItems[selectedTab].faqItems.map((faqItem) => (
            <CodeVilleAccordion key={faqItem.accordionQuestion} {...faqItem} customClass={styles.faqItem} />
          ))}
        </aside>
      </section>
    </main>
  );
};

export { CodeVilleFAQPage };
