import React from "react";
import { LinkIcon } from "../../../../assets";

import styles from "./styles.module.css";

const BlogCard = ({ header, paragraph, link }) => {
  return (
    <div className={styles.container}>
      <h1>{header}</h1>
      <p>{paragraph}</p>
      <div className={styles.link}>
        <a href={link} target="_blank" rel="noreferrer">
          <LinkIcon />
          <span>LEARN MORE</span>
        </a>
      </div>
    </div>
  );
};

export { BlogCard };
