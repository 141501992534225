import React from "react";
import { Button, FaqCard, PageHeader } from "../../components";
import { SparkHeader } from "./components";
import {
  sparksImg,
  WalletIcon,
  CommentIcon,
  ThinkIcon,
  CalculatorIcon,
  learningp1,
  learningp2,
  Icon01,
  Icon02,
  Icon03,
  Icon04,
  Icon05,
  Google,
  Genesys,
  Bitfinex,
  Microsoft,
  ArrowRight,
} from "../../assets";
import { Header } from "../contactUs/components";

import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Sparks = () => {
  const navigation = useNavigate();

  return (
    <div className={styles.sparksWrapper}>
      <section>
        <div className={styles.sparksHeadWrapper}>
          <h1>One Step, Limitless opportunities</h1>
          <p>
            Sparks aims to help as many women as possible transition into tech careers by teaching the required skills
            and fostering a community where they can find support, on their way up the ladder.
          </p>
          <Button filledType={true} >
            <a className={styles.customLink } href="https://bit.ly/genesys-sparks" target="_blank" rel="noreferrer"> Join the community</a>
          </Button>
        </div>
        <div className={styles.sparksimgWrapper}>
          <img src={sparksImg} className={styles.sparksimg} />
        </div>
      </section>

      <section id="about">
        <SparkHeader
          header="Our Goal"
          paragraph="We are on a mission to help women start their careers in tech
                    by teaching them the necessary skills, and providing a community 
                    to enable their fast-paced growth."
        />
        <div className={styles.sparksGoalCardBigWrapper}>
          <div className={styles.sparksGoalCardWrapper}>
            <div className={styles.sparksGoalCard}>
              <div>
                <WalletIcon />
                <p>Practical learning sessions</p>
              </div>
            </div>
            <div className={styles.sparksGoalCard}>
              <div>
                <CalculatorIcon />
                <p>Hands-on projects</p>
              </div>
            </div>
          </div>

          <div className={styles.sparksGoalCardWrapper2}>
            <div className={styles.sparksGoalCard}>
              <div>
                <ThinkIcon />
                <p>Mentorship with experienced hands</p>
              </div>
            </div>
            <div className={styles.sparksGoalCard}>
              <div>
                <CommentIcon />
                <p>Learning and growth with the community</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how-it-works">
        <Header className={styles.sHeader} name="How To Apply" />
        <div className={styles.htaCardWrapper}>
          <div className={styles.htaCard}>
            <div className={styles.brokenLine}></div>
            <Icon01 className={styles.sparksIcon} />
            <div className={styles.sparksIconWrapper}>
              <h4>Apply Online</h4>
              <p>Fill in the application form we provided</p>
            </div>
          </div>
          <div className={styles.htaCard}>
            <div className={styles.brokenLine}></div>
            <Icon02 className={styles.sparksIcon} />
            <div className={styles.sparksIconWrapper}>
              <h4>Phone Call Interview</h4>
              <p>We’ll put a phone call across to you</p>
            </div>
          </div>
          <div className={styles.htaCard}>
            <div className={styles.brokenLine}></div>
            <Icon03 className={styles.sparksIcon} />
            <div className={styles.sparksIconWrapper}>
              <h4>Virtual Interview</h4>
              <p>You’ll be invited for a virtual interview</p>
            </div>
          </div>
          <div className={styles.htaCard}>
            <div className={styles.brokenLine}></div>
            <Icon04 className={styles.sparksIcon} />
            <div className={styles.sparksIconWrapper}>
              <h4>Get Selected</h4>
              <p>Get an email from us confirming that you are in</p>
            </div>
          </div>
          <div className={styles.htaCard}>
            <div className={styles.brokenLine}></div>
            <Icon05 className={styles.sparksIcon} />
            <div className={styles.sparksIconWrapper}>
              <h4>Start the experience</h4>
              <p>Begin your SPARKS journey!</p>
            </div>
          </div>
        </div>
        <div className={styles.sparksButtonWrapper}>
          <Button filledType className={styles.contactUsButton}>
            <a className={styles.customLink } href="https://bit.ly/genesys-sparks" target="_blank" rel="noreferrer"> APPLY</a>
          </Button>
        </div>
      </section>

      <section id="learning">
        <SparkHeader
          header="Learning paths"
          paragraph="We’ve carved out 2 major learning paths for you to explore.
               If you are having a hard time making choice, get in touch, we’d be happy to help."
        />

        <div className={styles.lpCardWrapper}>
          <div className={styles.lpCard}>
            <img src={learningp2} className={styles.lpImg1} />
            <div>
              <h3>Product Design</h3>
              <p>
                Designing User Interface (UI), Crafting User Experience (UX), Branding Products, Design Thinking,
                Buiding Information Architecture, Copy Writing for Projects
              </p>
            </div>
          </div>

          <div className={styles.lpCard1}>
            <img src={learningp1} className={styles.lpImg1} />
            <div>
              <h3>Software Development</h3>
              <p>
                Programming with Javascript, Version control with Git, Web development with React, Building APIs with
                NodeJS, Managing Data with MongoDB, Deploying with Heroku
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <p className={styles.mentorsText}>Our Mentors are from</p>
        <div className={styles.mentorsIconsWrapper}>
          <div>
            <img src={Google} alt="google icon" />
            <img src={Microsoft} alt="microsoft" />
          </div>
          <div>
            <img src={Bitfinex} alt="Bitfinex" />
            <img src={Genesys} alt="genesys" />
          </div>
        </div>
      </section>

      <section id="faq">
        <div className={styles.faqContainer}>
          <div>
            <PageHeader smallHeader="faq" header="Need More Help?" />
            <div className={`subcontainer`}>
              <Button onClick={() => navigation("/contact")} className={styles["contact-button-a"]}>
                <span>CONTACT US </span>
                <span style={{ marginLeft: "10px" }}>
                  <ArrowRight />
                </span>
              </Button>
            </div>
          </div>
          <div className="subcontainer">
            <FaqCard header="Is SPARKS a paid training?" paragraph="No, SPARKS is completely free." />
            <FaqCard
              header="Do I need to be physically available?"
              paragraph="No, you can be part of the SPARKS experience virtually."
            />
            <FaqCard
              header="Do I need any prior experience?"
              paragraph="Not exactly, but if you know the basics, it’ll make
              your journey easier."
            />
            <FaqCard header="Do I get a certificate at the end of the programme?" paragraph="Yes you will." />
            <FaqCard
              header="Is the Program just for Nigerians?"
              paragraph="No, SPARKS is designed for women from all
              around the world."
            />
            <Button onClick={() => navigation("/contact")} className={styles["contact-button-b"]}>
              <span>CONTACT US </span>
              <span style={{ marginLeft: "10px" }}>
                <ArrowRight />
              </span>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export { Sparks };
