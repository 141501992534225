import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

const WhatWeAreIntoCard = ({ header, paragraph, link, background }) => {
  return (
    <div
      className={styles.container}
      style={{
        background: "#cecdcd",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={styles["text-container"]}>
        <h1>{header}</h1>
        <p>{paragraph}</p>
        <Link to={link}>LEARN MORE</Link>
      </div>
    </div>
  );
};

export { WhatWeAreIntoCard };
