import { tenece, hubly, google } from "../../../assets/svg";
import { talentBoy, talentGirl, talenGroup, avater } from "../../../assets/images";

export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 2,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
  },
};

export const mobileSliderBoxStyle = {
  display: "flex",
  width: "100%",
  background: "#F8F8F8",
  alignItems: "center",
  height: "100%",
};
export const jsonDataMobileSlider = [
  {
    icon: hubly,
  },
  {
    icon: tenece,
  },
  {
    icon: google,
  },
];

export const jsonDataSliderOne = [
  {
    imgSrc: `${talentBoy}`,
    role: "Backend Developer at",
    name: "Philip Matthew Ajibola",
    workPlace: "(Google)",
  },
  {
    imgSrc: `${talentGirl}`,
    role: "Backend Developer at",
    name: "Philip Matthew Ajibola",
    workPlace: "(Google)",
  },
  {
    imgSrc: `${talenGroup}`,
    role: "Backend Developer at",
    name: "Philip Matthew Ajibola",
    workPlace: "(Google)",
  },
];

export const jsonDataSliderTwo = [
  {
    src: `${avater}`,
    role: "CEO, CruiseNation.",
    name: "Mr. John Ebele",
    des: "My child was part of the interns for the last learnable internship progran that was organised by Genesys Tech Hub, and were honestly impressed with the team, and what they are building.",
  },
  {
    src: `${avater}`,
    role: "CEO, CruiseNation.",
    name: "Mr. John Ebele",
    des: "My child was part of the interns for the last learnable internship progran that was organised by Genesys Tech Hub, and were honestly impressed with the team, and what they are building.",
  },
  {
    src: `${avater}`,
    role: "CEO, CruiseNation.",
    name: "Mr. John Ebele",
    des: "My child was part of the interns for the last learnable internship progran that was organised by Genesys Tech Hub, and were honestly impressed with the team, and what they are building.",
  },
];

export const sliderBoxStyleTwo = {
  display: "flex",
  width: "100%",
  background: "#F6FCF8",
};

export const multiWrap = {
  width: "97%",
  margin: "auto",
};

export const mainContentWrap = {
  display: "flex",
  flexDirection: "column",
  marginBlock: "10px",
  marginInlineStart: "40px",
  width: "83%",
};

export const cardImg = {
  objectFit: "contain",
  marginInlineEnd: "12px",
  height: "80px",
  width: "80x",
};

export const cardtitle = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  color: "#191919",
};
export const personalData = {
  height: "43%",
  display: "flex",
  alignItems: "center",
  marginBlockStart: "20px",
};
export const nameWrap = {
  paddingBlock: "15px",
};
export const cardRole = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#CFCFCF",
};

export const sliderOnecontainer = {
  border: "1px solid #EEEEEE",
  borderRadius: "10px",
  height: "325px",
  width: "328px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
};

export const cusImg = {
  maxWidth: "100%",
  objectFit: "contain",
  maxHeight: "auto",
};

export const title = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  marginBlock: "0",
  color: "#191919",
  paddingBlock: "18px 8px",
};

export const info = {
  display: "flex",
  alignItems: "start",
  justifycontent: "spaceBetween",
};

export const grey = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#BABABA",
  margin: "0",
};

export const sliderBoxStyle = {
  background: "white",
  width: "100%",
};

export const blueText = {
  color: "#009CEB",
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  margin: "0",
};

export const manner = {
  autoSliding: { interval: "3s" },
  duration: "2s",
};

export const buttonSetting = {
  placeOn: "middle-inside",
  hoverEvent: true,
  style: {
    left: {
      height: "50px",
      width: "50px",
      color: "#929393",
      background: "rgba(225, 228, 232, 0.8)",
      borderRadius: "50%",
    },
    right: {
      height: "50px",
      width: "50px",
      color: "#929393",
      background: "rgba(225, 228, 232, 0.8)",
      borderRadius: "50%",
    },
  },
};
