import React from "react";
import styles from "./styles.module.css";

const CodeVilleButton = ({ label = "", onClick = () => {}, buttonType = "primary", icon, ...rest }) => {
  return (
    <button className={`${styles.button} ${styles[buttonType]}`} onClick={onClick} {...rest}>
      {rest?.link ? (
        <a href={rest.link} rel="noreferrer" target="_blank">
          {label}
        </a>
      ) : (
        label
      )}
      {icon && <span className={styles.buttonIcon}>{icon}</span>}
    </button>
  );
};

export { CodeVilleButton };
