import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

const IncubationCard = ({ header, paragraph, link, image }) => {
  return (
    <div className={styles.container}>
      <div className={styles["image-container"]}>
        <img src={image} alt={`${header}-img`} />
      </div>
      <div className={styles["text-container"]}>
        <h1>{header}</h1>
        <p>{paragraph}</p>
        <Link to={link}>LEARN MORE</Link>
      </div>
    </div>
  );
};

export { IncubationCard };
