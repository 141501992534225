import React from "react";

import styles from "./styles.module.css";

const Header = ({name, className}) => {
  return (
            <div className={`${className} ${styles.headWrapper}`}>
                <h1 className={styles.header}>{name}</h1>
            </div>               
        );
    };

export { Header };