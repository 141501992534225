import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Cancel, Hamburger, logo } from "../../assets";

import styles from "./styles.module.css";

const SparksHeader = () => {
  const [displayMobileNav, setDisplayMobileNav] = useState(false);

  const { hash } = useLocation();

  useEffect(() => {
    window.location.hash === "none";
  }, [hash]);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.container}>
      {/* mobile screens and small tablets */}
      <header className={styles["small-header"]}>
        <div>
          <NavLink end to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <Hamburger className={styles.hamburger} onClick={() => setDisplayMobileNav(true)} />
        {displayMobileNav ? (
          <nav>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive && hash === "" ? styles.active : "")}
                  onClick={() => {
                    scrollToTop();
                    setDisplayMobileNav(false);
                  }}
                  end
                  to="/sparks"
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/sparks#about"
                  // className={hash === "#about" ? styles.active : ""}
                  scroll={(el) => scrollWithOffset(el, 120)}
                  onClick={() => setDisplayMobileNav(false)}
                >
                  ABOUT
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/sparks#how-it-works"
                  // className={hash === "#how-it-works" ? styles.active : ""}
                  scroll={(el) => scrollWithOffset(el, 120)}
                  onClick={() => setDisplayMobileNav(false)}
                >
                  HOW IT WORKS
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/sparks#learning"
                  // className={hash === "#learning" ? styles.active : ""}
                  scroll={(el) => scrollWithOffset(el, 120)}
                  onClick={() => setDisplayMobileNav(false)}
                >
                  LEARNING
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/sparks#faq"
                  // className={hash === "#faq" ? styles.active : ""}
                  scroll={(el) => scrollWithOffset(el, 120)}
                  onClick={() => setDisplayMobileNav(false)}
                >
                  FAQ
                </HashLink>
              </li>
            </ul>
            <Cancel className={styles.cancel} onClick={() => setDisplayMobileNav(false)} />
          </nav>
        ) : (
          ""
        )}
      </header>
      <header className={styles["big-header"]}>
        <div>
          <NavLink end to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <nav>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive && hash === "" ? styles.active : "")}
                end
                to="/sparks"
                onClick={scrollToTop}
              >
                HOME
              </NavLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/sparks#about"
                // className={hash === "#about" ? styles.active : ""}
                scroll={(el) => scrollWithOffset(el, 150)}
              >
                ABOUT
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/sparks#how-it-works"
                // className={hash === "#how-it-works" ? styles.active : ""}
                scroll={(el) => scrollWithOffset(el, 150)}
              >
                HOW IT WORKS
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/sparks#learning"
                // className={hash === "#learning" ? styles.active : ""}
                scroll={(el) => scrollWithOffset(el, 150)}
              >
                LEARNING
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/sparks#faq"
                // className={hash === "#faq" ? styles.active : ""}
                scroll={(el) => scrollWithOffset(el, 150)}
              >
                FAQ
              </HashLink>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export { SparksHeader };
