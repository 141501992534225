import React from "react";

import styles from "./styles.module.css";

const HalfGridCard = ({ smallHeader, LargeHeader, paragraph }) => {
  return (
    <div className={`${styles.wrapper}`}>
      <h6>{smallHeader}</h6>
      <div>
        <div>
          <h2>{LargeHeader}</h2>
        </div>
        <div>
          <p>{paragraph}</p>
        </div>
      </div>
    </div>
  );
};

export { HalfGridCard };
