import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./styles.module.css";

const WhatWeDoCard = ({ headerOne, headerTwo, image, listItems }) => {
  return (
    <div
      className={styles["container"]}
      style={{
        background: "#cecdcd",
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className={styles["text-container"]}>
        <h1>{headerOne}</h1>
        <h2>{headerTwo}</h2>
      </div>
      <div className={styles["list-container"]}>
        <ul>
          {listItems.map((item, index) => (
            <li key={index}>
              {item?.isOutside ? (
                <a href={item?.link} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              ) : (
                <NavLink to={item.link ? item?.link : item.name}>{item.name}</NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { WhatWeDoCard };
