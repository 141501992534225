import React from "react";
import { aboutUsHeroImg, aboutUsMissonImage } from "../../assets";
import styles from "./styles.module.css";

const CodeVilleAboutUsPage = () => {
  return (
    <main className={styles.aboutUsContainer}>
      <header className={`codevilleSectionWrapper ${styles.aboutUsHeader}`}>
        <h6>ABout us</h6>
        <h2>Codeville</h2>

        <img src={aboutUsHeroImg} alt="" />
        <section className={styles.aboutUsHeaderContent}>
          <p>
            Codeville is a one-month STEM summer program where children get introduced to new concepts, assemble and
            program robots, learn to code while diving into the captivating world of technology. Our tutors invest
            themselves in igniting creative impulses through our learning science that takes a hands-on approach in the
            creation of an experience that is truly immersive and exciting.
          </p>
          <p>
            We understand that young children develop an understanding of technology when given the opportunity to
            engage in practical learning and also that the world today is technologically inclined and are giving the
            opportunity to children to get a headstart
          </p>
          <p>
            The main objective of this project is to give the children within our reach the opportunity to get a
            headstart in this world of technology and to ensure that the skills learnt can be expressed in solving their
            everyday problems.
          </p>
          <p>
            Not only do we have them learn technical skills, we also engage them in character building classes that
            cover topics like financial literacy, accountability, responsibility and so on.
          </p>
          <p>
            So far, we have trained 36 responsible, accountable and financially literate children in coding, graphics
            and robotics, each having their individual robotics kit, assembling and programming them themselves.
          </p>
        </section>
      </header>

      <div className={styles.sectionWrapper}>
        <section className={styles.aboutUsMissionContainer}>
          <aside>
            <h2>Our Mission</h2>
            <p>
              To give the children within our reach the opportunity to get a headstart in this world of technology and
              to ensure that the skills learnt can be expressed in solving their everyday problems.
            </p>
          </aside>
          <aside className={styles.aboutUsMissionImage}>
            <img src={aboutUsMissonImage} alt="" />
          </aside>
        </section>
        <section className={styles.aboutUsVisionContainer}>
          <aside className={styles.visionTextContent}>
            <h2>
              <span></span>
              Our Vision
            </h2>
            <p>
              A functioning global community where skilled kids are able to take on real-world problems and solve them
              with technology, helping eachother learn and grow in that process.
            </p>
          </aside>
          <aside className={styles.trainStatisticsContainer}>
            <h1>Over 36 + Children Trained So Far.</h1>
          </aside>
        </section>
      </div>
    </main>
  );
};

export { CodeVilleAboutUsPage };
