import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, ourApproachImg1, ourApproachImg2, springboardImg1 } from "../../assets";
import { Button, FaqCard, PageHeader, Stepper } from "../../components";
import { OurApproachCard } from "./component";

import styles from "./styles.module.css";

const StartZoneSpringBoard = () => {
  const navigation = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.navigate}>
        <span onClick={() => navigation("/startzone/business-incubation")}>
          <ArrowRight style={{ transform: "rotate(180deg)", marginRight: "10px" }} />
          Back
        </span>
      </div>
      <section id="about">
        <div>
          <PageHeader smallHeader="ABOUT Springboard" header="Learn All you need to grow your business" removePadding />
          <p>
            Within 3 months of active learning, you can learn everything you need to give your business a boost. From
            concepts, to strategy, to best practices, Springboard is designed to give practical knowledge that would
            serve as the bedrock for the positive growth of your business.
          </p>
          <Button filledType={true} disabled={true}>
            APPLICATIONS ARE CLOSED
          </Button>
        </div>
        <div>
          <img src={springboardImg1} alt="springboardimg1" />
        </div>
      </section>
      <section>
        <div>
          <PageHeader smallHeader="THE PROCEDURE" header="How It Works" removePadding />
          <p>Want to learn with Springboard? Need help in bosting your business? Here’s how to apply to get started.</p>
        </div>
        <div>
          <Stepper
            steps={[
              {
                header: "Online Application",
                paragraph: "We’ll take some of your info through an online form you’ll fill.",
              },
              {
                header: "Objective Vetting",
                paragraph: "Here, you get to sit tight while we go through your details.",
              },
              {
                header: "Virtual Interview",
                paragraph: "We’d love to have a chat with you here, get familiar a step further",
              },
              {
                header: "Onboarding",
                paragraph: "We get to start this amazing experience.",
              },
            ]}
          />
        </div>
      </section>
      <section>
        <div>
          <PageHeader smallHeader="What you will learn" header="Our Learning Approach" removePadding />
          <p>
            Our curriculum is carefully vetted by experienced hands who have a portfolio showing practical successes in
            active businesses, designed to help any startup go from 0-100, within a proper timeframe.{" "}
          </p>
        </div>
        <div className={styles["approach-card-container"]}>
          <OurApproachCard
            header="The Basics"
            paragraph="From best practices while setting up, to the right methods and tools for cost control, all you need to know when starting out as a business."
            image={ourApproachImg1}
          />
          <OurApproachCard
            header="The Business"
            paragraph="Getting your hands dirty, properly positioning for recognition, as well as funding. Experimentation models, building sales strategies and implementing. Everything to get your business the output it needs"
            image={ourApproachImg2}
          />
        </div>
      </section>
      <section id="faq">
        <div className={styles["faq-container"]}>
          <div>
            <PageHeader smallHeader="faq" header="Need More Help?" />
            <div className={`subcontainer`}>
              <Button onClick={() => navigation("/contact")} className={styles["contact-button-a"]}>
                <span>CONTACT US </span>
                <span style={{ marginLeft: "10px" }}>
                  <ArrowRight />
                </span>
              </Button>
            </div>
          </div>
          <div className="subcontainer">
            <FaqCard
              header="Is Springboard a paid program?"
              paragraph="No, Springboard is free for every young business that
              wishes to grow."
            />
            <FaqCard
              header="What do I gain from ther program?"
              paragraph="Practical knowledge about business concepts from the most 
              experienced minds."
            />
            <FaqCard
              header="Does my business have to be fully operational before I apply?"
              paragraph="No, you don’t need to have everything figured out before you
              join Springboard."
            />
            <FaqCard header="Do I get a certificate at the end of the program?" paragraph="Yes, you do." />
            <FaqCard
              header="Is the program just for Nigerians?"
              paragraph="No, Springboard is perfect for business mainly situated in the
              West African region."
            />
            <Button onClick={() => navigation("/contact")} className={styles["contact-button-b"]}>
              <span>CONTACT US </span>
              <span style={{ marginLeft: "10px" }}>
                <ArrowRight />
              </span>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export { StartZoneSpringBoard };
