import React from "react";

import styles from "./styles.module.css";

const NewGroundsCard = ({ Icon, header, paragraph }) => {
  return (
    <div className={styles.container}>
      <div>{Icon}</div>
      <h1>{header}</h1>
      <p>{paragraph}</p>
    </div>
  );
};

export { NewGroundsCard };
