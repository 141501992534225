import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Google, meta, startUpBoostImg1 } from "../../assets";
import { Button, ContactUsBox, PageHeader, Stepper } from "../../components";
import { NewGroundsCard, newGroundsData } from "./component";
import { Link } from 'react-router-dom';

import styles from "./styles.module.css";

const StartZoneStartUpBoost = () => {
  const navigation = useNavigate();
  const startUplink = "https://forms.gle/ndwJ38dp5ejT5Q7o6"

  return (
    <div className={styles.container}>
      <div className={styles.navigate}>
        <span onClick={() => navigation("/startzone/business-incubation")}>
          <ArrowRight style={{ transform: "rotate(180deg)", marginRight: "10px" }} />
          Back
        </span>
      </div>
      <section id="about">
        <div>
          <PageHeader header="get Expert Insight To Boost your business " removePadding />
          <p>
            Startup Boost offers every young business an opportunity to gain access to a wealth of practical business
            knowledge and resources need for the rapid growth and development of a young business. Our repository of
            mentors are seasoned professionals who have experience with the world’s leading organisations.
          </p>
          <Link to={startUplink} target="_blank" className={styles.customLink}>
          <Button filledType={true} disabled={false}>
            APPLICATIONS ARE OPEN
          </Button>
          
          </Link>
         
        </div>
        <div>
          <img src={startUpBoostImg1} alt="springboardimg1" />
        </div>
      </section>
      <section>
        <div className={styles.partners}>
          <p>Our Mentors are from</p>
          <div>
            <img src={Google} alt="google-img" />
            <img src={meta} alt="meta-img" />
          </div>
        </div>
      </section>
      <section>
        <PageHeader header="Break New Grounds" removePadding />
        <div>
          <p>
            Join our 6 months intensive program to experience real growth, gain relevant knowledge, and beat heavy
            milestones.
          </p>
        </div>
        <div className={styles["start-up-boost-grid"]}>
          {newGroundsData.map((item, index) => {
            return (
              <div key={`${item.header} + ${index}`}>
                <NewGroundsCard Icon={<item.Icon />} header={item.header} paragraph={item.paragraph} />
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div>
          <PageHeader smallHeader="THE PROCEDURE" header="How It Works" removePadding />
          <p>Want to learn with Springboard? Need help in bosting your business? Here’s how to apply to get started.</p>
        </div>
        <div>
          <Stepper
            steps={[
              {
                header: "Online Application",
                paragraph: "We’ll take some of your info through an online form you’ll fill.",
              },
              {
                header: "Objective Vetting",
                paragraph: "Here, you get to sit tight while we go through your details.",
              },
              {
                header: "Virtual Interview",
                paragraph: "We’d love to have a chat with you here, get familiar a step further",
              },
              {
                header: "Onboarding",
                paragraph: "We get to start this amazing experience.",
              },
            ]}
          />
        </div>
      </section>
      <section>
        <ContactUsBox
          smallHeader="Take the leap"
          header={"Ready to grow your business? "}
          paragraph="If you are thinking in the line of positive growth, development, and learning for your business, we’d like to hear from you."
        />
      </section>
    </div>
  );
};

export { StartZoneStartUpBoost };
