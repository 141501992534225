import React from 'react';
import { HalfGridCard, FullGridCard } from './components';
import { ContactUsBox } from '../../components';
import { img1, img2, img3, img4, img5, profileFive, profileFour, profileOne, profileSix, profileThree, profileTwo } from '../../assets/images';

import styles from './styles.module.css';
import { ProfileCard } from './components/fullGridCard/profileCard';

const AboutUs = () => {
    return(
        <>
            <section>
                <HalfGridCard
                    smallHeader="ABOUT US" 
                    LargeHeader="We Are Africa's Biggest Tech Talent Accelerator"
                    paragraph="In 5 years, we have helped over 1000 young Africans build capacity in the areas of 
                    software development and Design. We have groomed world-class talent, and we are super proud of it."
                />
            </section>
            <section className={styles.gallery}>
                <div className={styles.galleryRow}>
                    <div className={styles.galleryCol1}>
                        <img className={styles.img1} src={img1} alt="gallery-image" />
                    </div>
                    <div className={styles.galleryCol2}>
                        <img className={styles.img2} src={img2} alt="gallery-image" />
                    </div>
                </div>
            </section>            
            <section>
            <FullGridCard 
                smallHeading= "WHAT WE SET OUT TO BE"
                largeHeading="Our Mission" 
                firstParagraph="We are on a journey to build Africa’s largest pool of technical talent, 
                by helping as many young people learn the necessary skills to build a career in tech.
                "
            />               
            </section>
            <section className={styles.gallery2}>
                <div className={styles.gallery2HeaderWrapper}>
                    <h6 className={styles.gallery2smallerHeading}>OUR TARGET AUDIENCE</h6>
                    <h1 className={styles.gallery2largeHeading}>Who We Serve</h1>
                </div>
                <div className={styles.gallery2Row}>
                    <div className={styles.gallery2Col}>
                        <img className={styles.img3} src={img3}  alt="gallery-image" />
                        <div className={styles.gallery2Text} >
                            <h6>Techie</h6>
                            <p className={styles.gallery2Paragraph}>Aspiring software developers, designers and techies</p>
                        </div>
                    </div>
                    <div className={styles.gallery2Col}>
                        <img className={styles.img3} src={img4}  alt="gallery-image" />
                        <div className={styles.gallery2Text}>
                            <h6>Entrepreneurs</h6>
                            <p className={styles.gallery2Paragraph} >Startup Founders</p>
                        </div>
                    </div>
                    <div className={styles.gallery2Col}>
                        <img className={styles.img3} src={img5}  alt="gallery-image" />
                        <div className={styles.gallery2Text}>
                            <h6>Clients</h6>
                            <p className={styles.gallery2Paragraph} >Product owners trying to build new software</p>
                        </div>
                    </div>
                </div>
            </section>                           
            <section>
                <FullGridCard 
                    smallHeading= "HOW WE BEHAVE"
                    largeHeading="Our Culture" 
                    firstParagraph="We create opportunities for young Nigerian techies and entrepreneurs to grow and 
                    innovate, and a platform for the collaborations that make the impossible a days work."
                    secondParagraph="We are committed to mentoring a generation of innovators and entrepreneurs with 
                    a focus on excellence, constant growth and improvement."
                />
            </section>
            <section className={styles.gallery3}>
      <div className={styles.gallery2HeaderWrapper}>
          <h6 className={styles.gallery2smallerHeading}>The Executive Team</h6>  
          <h1 className={styles.gallery2largeHeading}>Our Superstars</h1>
        <div className={styles.flexRow}>
        <div className={styles.profile_container}>
      <ProfileCard img={profileOne} name={'Kingsley Eze'} role={'Co-Founder'} />
      <ProfileCard img={profileTwo} name={'Nnamdi Anika'} role={'Managing Director & Co-Founder'} />
      <ProfileCard img={profileThree} name={'Ekene Odum'} role={'Director of Operations'} />
      <ProfileCard img={profileFour} name={'Chinonso Uzoagbara'} role={'Manager of Brand & Corporate Communications'} />
    </div>
    <div className={styles.flexProfile}>
        <ProfileCard img={profileFive} name={'Eliezer Ajah'} role={'Coordinator of Learning & Development'} />
        <ProfileCard img={profileSix} role={'Advocate For People & Culture'} name={'Chizube Ejere'} />
        </div>
        </div>
      </div>
          
     
    </section>
            <section>
                <ContactUsBox
                    header="Are you Ready To Take that First Step?"
                    paragraph="If you are thinking in the line of positive growth, development, and learning, wed like to hear what you have to say."
                />
            </section>
        </>
    )
}
export { AboutUs };