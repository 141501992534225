import React, { Fragment, useEffect, useState, useRef } from "react";
import { ArrowRight, whatWeDoImg1, whatWeDoImg2, whatWeDoImg3 } from "../../assets";
import { Button, ContactUsBox, Loader, PageHeader } from "../../components";
import { MEDIUM_URL } from "../../config";
import { htmlParser } from "../../router/utils";
import { BlogCard, Popup, WhatWeDoCard } from "./components";

import styles from "./styles.module.css";

const Home = () => {
  const [blogPost, setBlogPost] = useState(null);
  // const [popUp, setPopUp] = useState(true);
  // const popUpRef = useRef(popUp); 

  useEffect(() => {
    fetch(MEDIUM_URL)
      .then((res) => res?.json())
      .then((data) => setBlogPost(data.items.filter((item) => item?.categories.length > 1)?.slice(0, 3)))
      .catch((error) => error);
  }, []);

  return (
    <Fragment>
     {/* {
      popUp && (<Popup state={popUpRef} setPopup={
        setPopUp
      } />)
     } */}
    <div className={styles.container}>
      <section>
        <PageHeader
          smallHeader="Who we are"
          header="We help Young Africans Start Their Careers In Technology"
          paragraph="Through our learning initiatives, young Africans can go from 0-100 in their technical skills within as little as 6 months, and join top teams all around the world right after."
        />
      </section>
      <section>
        <div className={styles["section-2-images"]}>
          {[0, 0, 0, 0].map((_, idx) => (
            <div key={idx}></div>
          ))}
        </div>
      </section>
      <section className={styles["section-3"]}>
        <div>
          <PageHeader smallHeader="Our Work" header="What We Do" />
          <p>
            Our goal is to help as many young techies and businesses reach that maximum potential, one step at a time.
            With us, truely everything is learnable.
          </p>
        </div>
        <div className={styles["section-3-b"]}>
          <div className={styles["section-3-grid"]}>
            <div>
              <WhatWeDoCard
                headerOne="Learning"
                headerTwo="Educating young techies"
                listItems={[{ name: "learnable" }, { name: "codeville" }, { name: "sparks" }]}
                image={whatWeDoImg1}
              />
            </div>
            <div>
              <WhatWeDoCard
                headerOne="Startzone"
                headerTwo="Supporting startups"
                listItems={[
                  { name: "investment", link: "startzone/investment" },
                  { name: "incubation", link: "startzone/business-incubation" },
                ]}
                image={whatWeDoImg2}
              />
            </div>
            <div>
              <WhatWeDoCard
                headerOne="Ignite"
                headerTwo="Community building"
                listItems={[{ name: "ignite", link: "http://ignite.genesystechhub.com/", isOutside: true }]}
                image={whatWeDoImg3}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles["blog"]}>
        <PageHeader smallHeader="Our blog" header="Latest Updates" />
        <div className={styles["blog-card-container"]}>
          {blogPost ? (
            blogPost.map((item, index) => (
              <BlogCard
                key={index}
                header={
                  item?.title?.toLowerCase()?.length <= 40
                    ? item?.title?.toLowerCase()
                    : `${item?.title?.toLowerCase().slice(0, 40)}...`
                }
                link={item?.link}
                paragraph={
                  htmlParser(item?.description)?.length <= 100
                    ? htmlParser(item?.description)
                    : `${htmlParser(item?.description).slice(0, 100)}...`
                }
              />
            ))
          ) : (
            <Loader />
          )}
        </div>
        <div className={styles["button-container"]}>
          <a href="https://medium.com/genesys-tech-hub" target="_blank" rel="noReferrer">
            <Button>
              <span>SEE ALL </span>
              <span>
                <ArrowRight />
              </span>
            </Button>
          </a>
        </div>
      </section>
      <section>
        <ContactUsBox
          header={"Are you Ready To Take that First Step?"}
          paragraph={
            "If you are thinking in the line of positive growth, development, and learning, we’d like to hear what you have to say."
          }
        />
      </section>
    </div>
    </Fragment>
  );
};

export { Home };
