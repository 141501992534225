import React from "react";

import styles from "./styles.module.css";

const OurApproachCard = ({ header, paragraph, image }) => {
  return (
    <div className={styles.container}>
      <img src={image} alt={`${header}-img`} />
      <div className={styles["text-container"]}>
        <h1>{header}</h1>
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export { OurApproachCard };
