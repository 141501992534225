import React from "react";

import styles from "./styles.module.css";

const SparkHeader = ({header, paragraph}) => {
  return (
    <div className={styles.sparksHeaderWrapper}>
        <h1>{header}</h1>
        <p>{paragraph}</p>
    </div>
  );
};


export { SparkHeader };