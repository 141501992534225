import React, { useState } from "react";
import { InputField, Button, Toast } from "../../components/";
import { Header, Textarea } from "./components/";
import { img7, icon1, icon2, icon3 } from "../../assets/images";

import styles from "./styles.module.css";
import { SHEET_DB_URL } from "../../config";

const ContactUs = () => {
  const [details, setDetails] = useState({ name: "", email: "", phone: "", message: "" });
  const [displayToast, setDisplayToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const handleChange = ({ target: { name, value } }) => {
    setDetails({ ...details, [name]: value });
  };

  const handleContactUs = async (e) => {
    e.preventDefault();
    setLoad(true);
    fetch(SHEET_DB_URL, {
      method: "POST",
      body: JSON.stringify({ json: details, sheet: "Contact Us" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response?.status >= 400) {
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        } else {
          setToastDetails({ toastState: "success", message: "Response taken!" });
          setDisplayToast(true);
          setDetails({ name: "", email: "", phone: "", message: "" });
        }
      })
      .catch(() => {
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      })
      .finally(() => setLoad(false));
  };

  return (
    <>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}
      <section className={styles.CUMain}>
        <div className={styles.contactUsBannerWrapper}>
          <h6 className={styles.contactUsSmallHeader}>CONTACT US</h6>
          <h1 className={styles.contactUsBanner}>We Really Can’t Wait To Hear From You!</h1>
        </div>

        <form className={styles.mainForm} onSubmit={handleContactUs}>
          <div className={styles.formWrapper}>
            <div className={styles.formRow}>
              <div className={styles.formCol1}>
                <InputField
                  className={styles.inputField}
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  value={details.name}
                  onChange={handleChange}
                  required={true}
                />
                <Textarea
                  type="text"
                  placeholder="Message"
                  rows="5"
                  cols="10"
                  name="message"
                  value={details.message}
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <div className={styles.formCol2}>
                <InputField
                  className={styles.inputField}
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={details.email}
                  onChange={handleChange}
                  required={true}
                />
                <InputField
                  type="number"
                  placeholder="Phone Number"
                  name="phone"
                  value={details.phone}
                  onChange={handleChange}
                  required={true}
                />
              </div>
            </div>
          </div>
          <Button className={styles.contactButton} filledType disabled={load}>
            {load ? "Loading..." : " Send Message"}
          </Button>
        </form>
      </section>

      <section className={styles.contactUsMapContainer}>
        <iframe
          className={styles.contactUsMap}
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3965.0232970659576!2d7.53165221426624!3d6.390995326463842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1044a728c2859721%3A0x36d000c156e333ae!2sGenesys%20Tech%20Hub%2C%20Lifestyle%20and%20Golf%20City%2C%20KM%207%20Port%20Harcourt%20-%20Enugu%20Expy%2C%20Ugwuaji%20400108%2C%20Enugu!3m2!1d6.3909899999999995!2d7.5338408999999995!5e0!3m2!1sen!2sng!4v1643713983811!5m2!1sen!2sng"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>

      <section className={styles.contactDetailWrapper}>
        <Header name="Other Ways To Reach Us" />
        <div className={styles.contactDetailRow}>
          <div className={styles.contactDetailCol}>
            <div className={styles.contactHeaderWrapper}>
              <img className={styles.icon} src={icon1} loading="lazy" />
              <h3 className={styles.contactHeader}>Contact</h3>
            </div>
            <div>
              <p className={styles.contactDetailText}>(+234) 8012 456 5678</p>
              <p className={styles.contactDetailText}>Hello@genesystechhub.com</p>
            </div>
          </div>

          <div className={styles.contactDetailCol}>
            <div className={styles.contactHeaderWrapper}>
              <img className={styles.icon} src={icon2} loading="lazy" />
              <h3 className={styles.contactHeader}>Location</h3>
            </div>
            <div>
              <p className={styles.contactDetailText}> Kilometer 7, Enugu/</p>
              <p className={styles.contactDetailText}> port Harcourt, Expressway</p>
              <p className={styles.contactDetailText}> Centenary City, Enugu. </p>
              <p className={styles.contactDetailText}> Nigeria </p>
            </div>
          </div>

          <div className={styles.contactDetailCol}>
            <div className={styles.contactHeaderWrapper}>
              <img className={styles.icon} src={icon3} loading="lazy" />
              <h3 className={styles.contactHeader}>Work Hours</h3>
            </div>
            <div>
              <p className={styles.contactDetailText}> Monday - Friday: 8am - 5pm</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className={styles.cUBrandHeaderWrapper}>
          <h1 className={styles.cUBrandHeader}>#weAreGenesys</h1>
        </div>
        <div className={styles.contactUsBrandContainer}>
          <img className={styles.img7} src={img7} alt="map" />
        </div>
      </section>
    </>
  );
};
export { ContactUs };
