import React from "react";
import { LinkIcon } from "../../../../assets";

import styles from "./styles.module.css";

const InvestmentCard = ({ img, paragraph, link, linkName }) => {
  return (
    <div className={styles.investmentCardWrapper}>
      <div className={styles.invCardIconWrapper}>
        <img src={img} alt="our partners" />
      </div>
      <div>
        <p>{paragraph}</p>
      </div>
      <div className={styles.link}>
        <a href={link} target="_blank" rel="noreferrer">
          <LinkIcon />
          <span>{linkName}</span>
        </a>
      </div>
  </div>
  );
};

export { InvestmentCard };