/* eslint-disable react/jsx-key */
import React from "react";
import {
  AboutUs,
  CodeVilleAboutUsPage,
  CodeVilleClosedAppPage,
  CodeVilleComponents,
  CodeVilleGalleryPage,
  CodeVilleJuniorAcademyGalleryPage,
  CodeVilleKidsCodeCampGalleryPage,
  CodeVillePastGraduatesPage,
  CodeVillePastProjectsPage,
  CodevilleTestimonialPage,
  CodeVilleContactPage,
  CodeVilleFAQPage,
  CodeVilleHomePage,
  CodeVilleJuniorPage,
  CodeVilleKidsCodeCamp,
  CodeVilleMiddleClassPage,
  ContactUs,
  Home,
  Investment,
  StartZone,
  StartZoneBusinessIncubation,
  StartZoneSpringBoard,
  Sparks,
  StartZoneStartUpBoost,
  CodeVilleJuniorVirtualPage,
  Learnable,
  OutsourcingAbout,
  OutsourcingContact,
  OutsourcingForEmployers,
  JoinOurCommunity,
} from "../../pages";

/**
 * MAIN ROUTES
 */

const mainRoute = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <AboutUs />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
];

/**
 * LEARNABLE ROUTES
 */

const learnableRoute = [
  {
    path: "/learnable",
    element: <Learnable />,
  },
];

/**
 * SPARKS ROUTES
 */

const sparksRoute = [
  {
    path: "/sparks",
    element: <Sparks />,
  },
];

/**
 * CODE_VILLE ROUTES
 */

const codeVilleRoutes = [
  {
    path: "/codeville/",
    element: <CodeVilleHomePage />,
  },
  {
    path: "/codeville/components",
    element: <CodeVilleComponents />,
  },
  {
    path: "/codeville/programmes/junior-academy",
    element: <CodeVilleJuniorPage />,
  },
  {
    path: "/codeville/programmes/middle-class",
    element: <CodeVilleMiddleClassPage />,
  },
  {
    path: "/codeville/programmes/kids-code-camp",
    element: <CodeVilleKidsCodeCamp />,
  },
  {
    path: "/codeville/programmes/virtual-camp",
    element: <CodeVilleJuniorVirtualPage />,
  },
  {
    path: "/codeville/gallery/junior-academy",
    element: <CodeVilleJuniorAcademyGalleryPage />,
  },
  {
    path: "/codeville/gallery",
    element: <CodeVilleGalleryPage/>
  },
  {
    path: "/codeville/gallery/kids-code-camp",
    element: <CodeVilleKidsCodeCampGalleryPage />
  },
  {
    path: "/codeville/gallery/past-projects",
    element: <CodeVillePastProjectsPage />,
  },
  {
    path: "/codeville/gallery/past-graduates",
    element: <CodeVillePastGraduatesPage/>
  },
  {
    path: "/codeville/testimonials",
    element: <CodevilleTestimonialPage/>
  },
  {
    path: "/codeville/about",
    element: <CodeVilleAboutUsPage />,
  },
  {
    path: "/codeville/contact",
    element: <CodeVilleContactPage />,
  },
  {
    path: "/codeville/faqs",
    element: <CodeVilleFAQPage />,
  },
  {
    path: "/codeville/closed-app",
    element: <CodeVilleClosedAppPage />,
  },
];

/**
 * STARTZONE ROUTES
 */

const startzoneRoute = [
  {
    path: "/startzone",
    element: <StartZone />,
  },
  {
    path: "startzone/investment",
    element: <Investment />,
  },
  {
    path: "/startzone/business-incubation",
    element: <StartZoneBusinessIncubation />,
  },
  {
    path: "/startzone/business-incubation/springboard",
    element: <StartZoneSpringBoard />,
  },
  {
    path: "/startzone/business-incubation/start-up-boost",
    element: <StartZoneStartUpBoost />,
  },
];

/**
 * OUTSOURCING ROUTES
 */

const outsourcingRoute = [
  {
    path: "/outsourcing",
    element: <OutsourcingAbout />,
  },
  {
    path: "/outsourcing/employers",
    element: <OutsourcingForEmployers />,
  },
  {
    path: "/outsourcing/contact",
    element: <OutsourcingContact />,
  },
  {
    path: "/outsourcing/join",
    element: <JoinOurCommunity />,
  },
];

export { mainRoute, learnableRoute, codeVilleRoutes, startzoneRoute, sparksRoute, outsourcingRoute };
