import React from "react";
import { Footer, StartzoneHeader } from "../../components";

import styles from "./styles.module.css";

const StartzoneLayout = ({ children }) => {
  return (
    <div className={`${styles.container} container`}>
      <StartzoneHeader />
      <div className={styles.children}>{children}</div>
      <Footer />
    </div>
  );
};

export { StartzoneLayout };
