import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "..";
import { ArrowRight } from "../../assets";

import styles from "./styles.module.css";

const ContactUsBox = ({ smallHeader = "WHAT WE CAN DO FOR YOU", header, paragraph }) => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.container}`}>
      <h1>{smallHeader}</h1>
      <div>
        <div>
          <h2>{header}</h2>
        </div>
        <div>
          <p>{paragraph}</p>
          <Button onClick={() => navigate("/contact")}>
            <span>CONTACT US </span>
            <span>
              <ArrowRight />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export { ContactUsBox };
