import React from "react";
import styles from "./styles.module.css";
import {
  CodeVilleHomeExperienceSection,
  CodeVilleHomeHeroSection,
  FunTeachExperienceSection,
  GallerySection,
  IntentionSection,
  KidsFeedbackSection,
} from "./widgets";

const CodeVilleHomePage = () => {
  return (
    <main className={styles.homepageContainer}>
      <CodeVilleHomeHeroSection />
      <CodeVilleHomeExperienceSection />
      <IntentionSection />
      <FunTeachExperienceSection />
      <GallerySection />
      <KidsFeedbackSection />
    </main>
  );
};

export { CodeVilleHomePage };
