import React from "react";
import { GradFacesIcon, graduateFaces } from "../../../../../assets";
import styles from "./styles.module.css";

const GallerySection = () => {
  return (
    <section className={styles.galleryContainer}>
      <h2>Faces of Our Amazing Graduates</h2>

      <aside className={styles.gradfacesContainer}>
        <img src={graduateFaces} alt="" />
        <GradFacesIcon className={styles.gradfacesIcon} />
      </aside>
    </section>
  );
};

export { GallerySection };
