import React from "react";
import { businessIncubationImg2, businessIncubationImg3, businessIncubationImg4 } from "../../assets";
import { ContactUsBox, PageHeader } from "../../components";
import { IncubationCard } from "./components";

import styles from "./styles.module.css";

const StartZoneBusinessIncubation = () => {
  return (
    <div className={styles.container}>
      <section id="about">
        <PageHeader
          smallHeader="About"
          header="Grow Your Business The Right Way"
          paragraph={
            "With the right guidance, every business can hack its growth and go from 0 to 100. We’ve figured the magic tricks, and we are more than willing to teach you how this thing goes."
          }
        />
      </section>
      <section></section>
      <section>
        <PageHeader smallHeader="Progress is Progress" header="Our Work" />
        <div className={styles["section-3-flex"]}>
          <div className="subcontainer">
            <p>
              Over the years, we’ve made it our business to help other businesses grow at a fast pace, by giving them
              the resources and knowledge to achieve one growth milestone after the other.
            </p>
            <p>
              By providing access to practical business knowledge, mentorship from experienced industry experts, and
              positioning for scaling and funding, we’ve groomed startups from one point to a higher one.
            </p>
            <p>
              So far, we’ve successfully contributed to the growth of over 15 young businesses through incubation, and
              an even larger pool of over 50 startups through our learning and growth initiatives.
            </p>
          </div>
          <div>
            <img src={businessIncubationImg2} alt="business-incubation-img2" />
          </div>
        </div>
      </section>
      <section>
        <PageHeader smallHeader="Our Arms" header="What We Are Into" />
        <div className={`${styles["incubation-cards-container"]} subcontainer`}>
          <IncubationCard
            header={"Start up Boost"}
            paragraph="Get prepared for your next round of funding by leveraging on our mentorship provided by industry experts."
            link="/startzone/business-incubation/start-up-boost"
            image={businessIncubationImg4}
          />
          <IncubationCard
            header={"Spring board"}
            paragraph="Get knowledge from experienced industry leaders to grow and support your business."
            link="/startzone/business-incubation/springboard"
            image={businessIncubationImg3}
          />
        </div>
      </section>
      <section>
        <ContactUsBox
          header={"Ready to grow your business? "}
          paragraph={
            "If you are thinking in the line of positive growth, development, and learning, we’d like to hear what you have to say."
          }
        />
      </section>
    </div>
  );
};

export { StartZoneBusinessIncubation };
