import { useLayoutEffect, useState } from 'react'

const usePageResize = () => {
  // Event listener for window resize
  const [windowSize, setWindowSize] = useState(0)

  // Effect to handle navbar type update
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { windowSize, setWindowSize }
}

export { usePageResize }
