import React from "react";
import { Footer, SparksHeader } from "../../components";

import styles from "./styles.module.css";

const SparksLayout = ({ children }) => {
  return (
    <div className={`${styles.container} container`}>
      <SparksHeader />
      <div className={styles.children}>{children}</div>
      <Footer />
    </div>
  );
};

export { SparksLayout };
