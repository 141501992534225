import React, {useState} from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

import projects_2023 from "../../../../../assets/data/projects-2023.json";
import { LivePreviewIcon } from "../../../../../assets";
import DataNotFoundImage from "../../../../../assets/images/codeville/gallery/data-not-found.png";

const PastProjects = () => {
	// Tab styles
	const active_tab = {
		background: "linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%)",
		color: "#eeeeee",
	}

	const projects_data = {
		"2021": null,
		"2022": null,
		"2023": projects_2023,
	}

	// const previousYear = (new Date().getFullYear() - 1).toString();

	const [pastProjects, setPastProjects] = useState({
		year: "2023", // default to 2023
		list: projects_data["2023"],
	});

	function fetchProjects(year) {
		const data = projects_data[year];
		if (data) {
			setPastProjects({
				year,
				list: data,
			});
		} else {
			setPastProjects({
				year,
				list: null,
			});
		}
	}

	return (
		<section className={styles.pastProjects}>
			<div className={styles.pastProjects_header}>
				<span>Projects</span>
				<h1>Check out these amazing projects created by some of our past interns</h1>
			</div>
			<div className={styles.pastProjects_content}>
				<div className={styles.content_tabs}>
					<span
						style={(pastProjects.year === "2021") ? active_tab : {}}
						onClick={() => fetchProjects("2021")}
					>2021</span>
					<span
						style={(pastProjects.year === "2022") ? active_tab : {}}
						onClick={() => fetchProjects("2022")}
					>2022</span>
					<span
						style={(pastProjects.year === "2023") ? active_tab : {}}
						onClick={() => fetchProjects("2023")}
					>2023</span>
				</div>

				{(pastProjects.list) ? (<>
					<div className={styles.pastProjects_list}>
						{React.Children.toArray((pastProjects.list).map((project, index) => (<>
							{/* Show only the first three items */}
							{(index < 3) && (<div key={project.id} className={styles.pastProjects_listItem}>
								<div className={styles.pastProjects_listItem_topSection}>
									<div className={styles.pastProjects_listItem_coverImage}>
										<img src={project.coverImage} />
									</div>
									<a target="_blank" href={project.livePreview} rel="noreferrer">
										<LivePreviewIcon />
										Live Preview
									</a>
								</div>
								<div className={styles.pastProjects_listItem_bottomSection}>
									<div className={styles.pastProjects_listItem_studentImage}>
										<img src={project.student_image} />
									</div>
									<div className={styles.pastProjects_listItem_details}>
										<h3>{project.student_name}</h3>
										<p>{project.school_name ?? "School not provided"}</p>
									</div>
								</div>
							</div>)}
						</>)))}
					</div>
					<Link target="_blank" to={"/codeville/gallery/past-projects"} className={styles.pastProjects_loadMore}>Load More Projects</Link>
				</>) : (<div className={styles.listNotFound}>
					<div className={styles.listNotFound_imageContainer}>
						<img loading="eager" src={DataNotFoundImage} />
					</div>
					<p>Gallery will be uploaded soon</p>
				</div>)}
			</div>
		</section>
	)
}

export {PastProjects};