import React, { useState } from "react";
import styles from "./styles.module.css";
import testimonialData from "./../../assets/data/testimonials.json";

const CodevilleTestimonialPage = () => {
  // Tab styles
  const active_tab = {
    background: "linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%)",
    color: "#eeeeee",
  };

  const videoData = {
    2023: "https://res.cloudinary.com/genesys/video/upload/v1717148797/codeville-gallery/testimonies/codeville-2023.mp4",
    2022: "https://res.cloudinary.com/genesys/video/upload/v1717149068/codeville-gallery/testimonies/codeville-2022.mp4",
    2019: "https://res.cloudinary.com/genesys/video/upload/v1717148797/codeville-gallery/testimonies/codeville-2019.mp4",
  };

  const [testimonialVid, setTestimonialVid] = useState({
    year: "2023",
    video: "https://res.cloudinary.com/genesys/video/upload/v1717148797/codeville-gallery/testimonies/codeville-2023.mp4",
  });

  const [testimonialSize, setTestimonialSize] = useState(4);

  function fetchVideo(year) {
    const data = videoData[year];
    if (data) {
      setTestimonialVid({
        year,
        video: data,
      });
    } else {
      setTestimonialVid({
        year,
        video: null,
      });
    }
  }

  return (
    <>
      <section className={styles.vanguard}>
        <div className={styles.vanguard_header}>
          <h1>Testimonial</h1>
          <p>
            With Codeville, African kids can be placed several steps ahead in the world&apos;s race for knowledge,
            positive growth, and technology breakthrough.{" "}
          </p>
        </div>

        <div className={styles.vanguard_content}>
          <div className={styles.content_tabs}>
            <span style={testimonialVid.year === "2019" ? active_tab : {}} onClick={() => fetchVideo("2019")}>
              2019
            </span>
            <span style={testimonialVid.year === "2022" ? active_tab : {}} onClick={() => fetchVideo("2022")}>
              2022
            </span>
            <span style={testimonialVid.year === "2023" ? active_tab : {}} onClick={() => fetchVideo("2023")}>
              2023
            </span>
          </div>
          <div className={styles.vanguard_contentWrapper}>
            <div className={styles.vanguard_videoWrapper}>
              <video controls preload="metadata" src={testimonialVid.video}>
                <source src={testimonialVid.video} type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.testimonies}>
        <div className={styles.testimoniesWrapper}>
          <div className={styles.testimonies_header}>
            <span>Parents Testimonials</span>
            <h1>Parents think we are amazing too</h1>
          </div>
          <div className={styles.testimonies_content}>
            <div className={styles.testimoniesContainer}>
              {/* First Row */}
              <div className={styles.testimoniesRow}>
                {testimonialData[0].map(
                  (testimony, index) =>
                    index < testimonialSize && (
                      <div className={styles.testimonyItem} key={index}>
                        <div className={styles.testimonyHeader}>
                          <h1>{testimony.name}</h1>
                        </div>
                        <div className={styles.testimonyContent}>
                          <p>{testimony.content}</p>
                        </div>
                      </div>
                    ),
                )}
              </div>
              {/* Second Row */}
              <div className={styles.testimoniesRow}>
                {testimonialData[1].map(
                  (testimony, index) =>
                    index < testimonialSize && (
                      <div className={styles.testimonyItem} key={index}>
                        <div className={styles.testimonyHeader}>
                          <h1>{testimony.name}</h1>
                        </div>
                        <div className={styles.testimonyContent}>
                          <p>{testimony.content}</p>
                        </div>
                      </div>
                    ),
                )}
              </div>
              {/* Third Row */}
              <div className={styles.testimoniesRow}>
                {testimonialData[2].map(
                  (testimony, index) =>
                    index < testimonialSize && (
                      <div className={styles.testimonyItem} key={index}>
                        <div className={styles.testimonyHeader}>
                          <h1>{testimony.name}</h1>
                        </div>
                        <div className={styles.testimonyContent}>
                          <p>{testimony.content}</p>
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>
            {/* <label
              onClick={() => {
                setTestimonialSize((prevState) => {
                  return prevState * 2;
                });
              }}
              className={styles.testimonies_loadMore}
            >
              Show More
            </label> */}
          </div>
        </div>
      </section>
    </>
  );
};

export { CodevilleTestimonialPage };
