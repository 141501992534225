import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AdvancedPattern,
  AdvancedPatternVirtual,
  EntryPattern,
  JuniorPattern,
  QuoteIcon,
  reviewImageBoy,
  reviewImageGirl,
  ReviewIndicator,
  RightArrowCadet,
  RoboticsIcon,
} from "../../../assets";
import { CodeVilleButton } from "../button";
import styles from "./styles.module.css";

const CodeVilleBasicCard = ({
  icon = <RoboticsIcon />,
  title = "Robotics",
  description = "I would recommend Codeville for every child out there because",
  customClassName = "",
}) => {
  return (
    <section className={`${styles.basicCardContainer} ${customClassName}`}>
      <span className={styles.cardVector}>{icon}</span>
      <h2>{title}</h2>
      <p>{description}</p>
    </section>
  );
};

const CodeVilleReviewCard = () => {
  const reviewItems = useMemo(
    () => [
      {
        review: `“I would recommend Codeville for every child out there because Codeville has not only made me a special 
      person, it has enlightened me and helped me look at the computer world from a whole different view”`,
        img: reviewImageGirl,
        reviewerName: "Chijindu Ijeoma",
        class: "",
        school: "",
        year: 2019,
      },
      {
        review: `“My experience at Codeville was really fun and interesting. I was taught how to program an Mbot and give 
      it different commands. I was also taught scratch and how to make games and other exciting things. 
      I recommend Codeville for every child that loves technology and hopes to be a future programmer 
      because Codeville will teach you how to write your first codes.”`,
        img: reviewImageBoy,
        reviewerName: "Ugochukwu Mbachu",
        class: "SS2 student",
        school: "Grand Vick International Schools",
        year: 2019,
      },
      {
        review: `“I was a little nervous when I eventually joined the program but it wasn’t long before 
      I made new friends and got very comfortable. In Codeville, I learnt how to program robots, how to use scratch, 
      how to assemble robots and many other things. I love Codeville because the teachers teach us very well. 
      Codeville is good for every child!”`,
        img: reviewImageBoy,
        reviewerName: "Otum Daniel",
        class: "Grade 4 student",
        school: "Spring of Life International School",
        year: "",
      },
    ],
    [],
  );

  // State variables
  const [activeIdx, setActiveIdx] = useState(0);
  const [currentItem, setCurrentItem] = useState(reviewItems[0]);

  // Update current item
  const handleCurrentReviewUpdate = (idx) => {
    setActiveIdx(idx);
    setCurrentItem(() => reviewItems[idx]);
  };

  // Update item periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIdx = activeIdx < reviewItems.length - 1 ? activeIdx + 1 : 0;
      setActiveIdx(newIdx);
      setCurrentItem(() => reviewItems[newIdx]);
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <section className={styles.reviewCardContainer}>
      <div key={currentItem?.review} className={styles.reviewCardItem}>
        <section className={styles.reviewCommentContainer}>
          <span className={styles.reviewQuoteIcon}>
            <QuoteIcon />
          </span>
          <aside className={styles.reviewComment}>
            <p>{currentItem?.review}</p>
          </aside>
        </section>

        <section className={styles.reviewerInfoContainer}>
          <span className={styles.reviewerAvatar}>
            <img src={currentItem?.img} alt="reviewer pic" />
          </span>

          <aside className={styles.reviewerInfo}>
            <h2>{currentItem?.reviewerName}</h2>
            <h5>
              {currentItem?.class && `${currentItem?.class} at`} {currentItem?.school}{" "}
              {currentItem?.year && `(Codeville ${currentItem?.year})`}
            </h5>
          </aside>
        </section>
      </div>

      <span className={styles.indicatorContainer}>
        {reviewItems.map((item, idx) => {
          const isActive = activeIdx === idx;
          return (
            <ReviewIndicator
              onClick={() => handleCurrentReviewUpdate(idx)}
              key={idx}
              className={`${styles.indicator} ${isActive ? styles.activeIndicator : ""}`}
            />
          );
        })}
      </span>
    </section>
  );
};

const CodeVilleProgramCard = ({ programType = "junior", customClassName = "", applyLink = "" }) => {
  const navigate = useNavigate();

  // Card type variables
  const PROGRAM_TYPES = useMemo(
    () => ({
      entry: {
        title: "Codeville Kids Code Camp",
        ageLimit: `8-10`,
        vectorPattern: EntryPattern,
        description: "Suitable for children within the ages of 9-12",
        comment:
          "This Codeville segment is perfect for kids who are totally new to everything tech. Here, your child will get introduced to the fundamentals of software devlopment, design, robotics, and mixed reality. They also would get the chance to play around with these concepts by solving practical problems. ",
        styles: {
          headerBg: "#E1F6F8",
          headerAgeBg: "#438991",
        },
      },
      junior: {
        title: "Codeville Middle Class",
        ageLimit: `8-10`,
        vectorPattern: JuniorPattern,
        description: "Suitable for Codeville Kids Code Camp graduates",
        comment:
          "This Codeville segment is perfect if your child has gone through the junior segment in the past. Here, our kids take a more advanced approach to learning about technology concepts. They also would attempt to solve more complex problems, as well specialise their learning based on a particular domain or skillset.",
        styles: {
          headerBg: "#CEFFF1",
          headerAgeBg: "#05A879",
        },
      },
      advanced: {
        title: "Codeville Junior Academy",
        ageLimit: `8-10`,
        vectorPattern: AdvancedPattern,
        description: "Suitable for children on junior WAEC break",
        comment:
          "As the name implies, this Codeville segment is specially tailored for kids who just took their Junior WAEC examination exercise. For a month, the kids here would take a deep dive into technology, helping them get a clear vision of the spaces they would want to perfect their skills in.",
        styles: {
          headerBg: "#FFE8CE",
          headerAgeBg: "#FA5C03",
        },
      },
      advancedVirtual: {
        title: "Codeville Virtual Camp",
        ageLimit: `8-10`,
        vectorPattern: AdvancedPatternVirtual,
        description: "Suitable for children on junior WAEC break",
        comment:
          "This segment is specifically made for kids that can not make it to the hub. The Codeville team understands that location should not be a barrier to learning and have built a curriculum that encourages deep learning in any chosen learning path in the period of one month from the comfort of every home.",
        styles: {
          headerBg: "#F8F5E6",
          headerAgeBg: "#ffa069",
        },
      },
    }),
    [],
  );

  // Selected Program Variables
  const selectedProgramCard = useMemo(() => PROGRAM_TYPES[programType], []);
  const SelectedPattern = selectedProgramCard.vectorPattern;

  // Apply button submit handler
  const applyButtonClick = () => {
    if (!applyLink) navigate("/codeville/closed-app");
    else window.open(applyLink, "_blank");
  };

  return (
    <section className={`${styles.programCardContainer} ${styles[programType]} ${customClassName}`}>
      <header className={styles.programCardHeader} style={{ backgroundColor: selectedProgramCard.styles.headerBg }}>
        <SelectedPattern />
        <h1>{selectedProgramCard.title}</h1>
        <p>{selectedProgramCard.description}</p>
      </header>

      <aside className={styles.programComment}>
        <p>{selectedProgramCard.comment}</p>
        <CodeVilleButton
          label="Apply"
          buttonType="secondary"
          icon={<RightArrowCadet />}
          style={{ width: "100%" }}
          onClick={applyButtonClick}
        />
      </aside>
    </section>
  );
};

const CodevilleLearningPathCard = ({ icon, headerText, paragraphOne, paragraphTwo, stackList }) => {
  return (
    <div className={styles.codeVilleLearningPathCard}>
      <div>
        <h3>{headerText}</h3>
        <img alt={`${headerText}-icon`} src={icon} />
      </div>
      <div>{paragraphOne}</div>
      {stackList ? <div className={styles.stackList}>{stackList}</div> : ""}
      <div>{paragraphTwo}</div>
    </div>
  );
};

export { CodeVilleBasicCard, CodeVilleReviewCard, CodeVilleProgramCard, CodevilleLearningPathCard };
