import React, { useState } from "react";
import { ContactUsIcon, LocationIcon, weAreGenesysImage, WorkHoursIcon } from "../../assets";
import { CodeVilleButton, CodeVilleInput, CodeVilleTextArea, Toast } from "../../components";
import { SHEET_DB_URL } from "../../config";
import styles from "./styles.module.css";

const CodeVilleContactPage = () => {
  const [details, setDetails] = useState({ name: "", email: "", phone: "", message: "" });
  const [displayToast, setDisplayToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const handleChange = ({ target: { name, value } }) => {
    setDetails({ ...details, [name]: value });
  };

  const handleContactUs = async (e) => {
    e.preventDefault();
    setLoad(true);
    fetch(SHEET_DB_URL, {
      method: "POST",
      body: JSON.stringify({ json: details, sheet: "Contact Us" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response?.status >= 400) {
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        } else {
          setToastDetails({ toastState: "success", message: "Response taken!" });
          setDisplayToast(true);
          setDetails({ name: "", email: "", phone: "", message: "" });
        }
      })
      .catch(() => {
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      })
      .finally(() => setLoad(false));
  };

  return (
    <main className={styles.contactUsContainer}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}
      <section>
        <header className={styles.contactUsHeader}>
          <h6>Contact us</h6>
          <h2>We Really Can’t Wait To Hear From You!</h2>
        </header>

        <form className={styles.inputsContainer} onSubmit={handleContactUs}>
          <div className={styles.inputsGroupWrapper}>
            <CodeVilleInput
              type="text"
              customClass={styles.input}
              placeholder="Full name"
              name={"name"}
              onChange={handleChange}
              value={details.name}
              required={true}
            />
            <CodeVilleInput
              type="text"
              customClass={styles.input}
              placeholder="Email address"
              name={"email"}
              onChange={handleChange}
              value={details.email}
              required={true}
            />
          </div>
          <div className={styles.inputsGroupWrapper}>
            <CodeVilleTextArea
              customClass={styles.input}
              placeholder="Message"
              name={"message"}
              onChange={handleChange}
              value={details.message}
              required={true}
            />
            <CodeVilleInput
              type="number"
              customClass={styles.input}
              placeholder="Phone number"
              name={"phone"}
              onChange={handleChange}
              value={details.phone}
              required={true}
            />
          </div>

          <CodeVilleButton
            style={{ width: "fit-content", marginTop: "40px" }}
            label={load ? "Loading...:" : "Send Message"}
            disabled={load}
          />
        </form>
        <aside className={styles.mapContainer}>
          <iframe
            className={styles.map}
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3965.0232970659576!2d7.53165221426624!3d6.390995326463842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1044a728c2859721%3A0x36d000c156e333ae!2sGenesys%20Tech%20Hub%2C%20Lifestyle%20and%20Golf%20City%2C%20KM%207%20Port%20Harcourt%20-%20Enugu%20Expy%2C%20Ugwuaji%20400108%2C%20Enugu!3m2!1d6.3909899999999995!2d7.5338408999999995!5e0!3m2!1sen!2sng!4v1643713983811!5m2!1sen!2sng"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </aside>
      </section>

      <section className={styles.waysToReachUs}>
        <h2>Other Ways To Reach Us </h2>
        <aside className={styles.waysToReachChannels}>
          <div className={styles.waysToReachChannel}>
            <h4>
              <ContactUsIcon className={styles.svg} /> Contact
            </h4>
            <p>
              <a href="tel: (+234)7037153284">(+234)7037153284</a> <br />
              <a href="tel: (+234)8124487288">(+234)8124487288</a>
            </p>
            <p> codeville@genesystechhub.com</p>
          </div>
          <div className={styles.waysToReachChannel}>
            <h4>
              <LocationIcon className={styles.svg} /> Location
            </h4>
            <p>Kilometer 7, Enugu/Port Harcourt, Expressway, Centenary City, Enugu. Nigeria</p>
          </div>
          <div className={styles.waysToReachChannel}>
            <h4>
              <WorkHoursIcon className={styles.svg} /> Work Hours
            </h4>
            <p>Monday - Friday: 8am - 5pm</p>
          </div>
        </aside>
      </section>

      <section className={styles.weAreGenContainer}>
        <h2>#weAreGenesys</h2>
        <img src={weAreGenesysImage} alt="we are genesys" />
      </section>
    </main>
  );
};

export { CodeVilleContactPage };
