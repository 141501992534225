import React from "react";
import { useNavigate } from "react-router-dom";
import {
  adobe,
  figma,
  funActivitiesImage,
  headPhoneImage,
  htmlTag,
  laptopImage,
  RightArrowWhite,
  TickIcon,
  wifiImage,
} from "../../assets";
import { CodeVilleAccordion, CodeVilleButton, CodevilleLearningPathCard } from "../../components";
import ProgrammeImage from "./../../assets/images/codeville/programmes/program-icon.png";
import RequirementImage1 from "./../../assets/images/codeville/programmes/requirement-image-01.png";
import RequirementImage2 from "./../../assets/images/codeville/programmes/requirement-image-02.png";
import { KidsFeedbackSection } from "../../pages/codevilleHome/widgets";
import styles from "./styles.module.css";

const CodeVilleProgramLayout = ({
  programFAQs = [],
  programTitle = "Codeville Kids Code Camp",
  programDesc = `Codeville represents our intentions and efforts to ensure that African children aren't left behind in the
                 world's race for rapid technology breakthrough.`,
  porgramAboutImage = funActivitiesImage,
  applyLink = "",
  specialVirtualPage = false,
  programDetails = null,
  tabElement = null,
  bannerElement = null,
}) => {
  const navigate = useNavigate();

  // Apply button Click hanler
  const applyButtonClick = () => {
    if (!applyLink) navigate("/codeville/closed-app");
    else window.open(applyLink, "_blank");
  };

  return (
    <section className={styles.programContainer}>
      <div className={styles.sectionWrapper}>
        <header className={`${styles.programHeader}`}>
          <h1>{programTitle}</h1>
          <p>{programDesc}</p>

          <CodeVilleButton
            buttonType="primary"
            label="Apply"
            onClick={applyButtonClick}
            style={{ marginTop: "48px" }}
            icon={<RightArrowWhite />}
          />
        </header>
      </div>
      <div className={styles.sectionWrapper}>
        <section className={styles.programAbout}>
          {tabElement}
          <div className={styles.programAboutWrapper}>
            <aside className={styles.programAboutImage}>
              <img src={porgramAboutImage} alt="student having fun" />
            </aside>
            <aside className={styles.programAboutTextContent}>
              <h6>{programDetails?.catchPhrase}</h6>
              <h2>About</h2>
              <p>{programDetails?.about}</p>
            </aside>
          </div>
        </section>

        <section className={styles.programme_tutorials}>
          <div className={styles.programme_tutorialHead}>
            <span>How we do it?</span>
            <h1>We Teach Children</h1>
          </div>
          <div className={styles.programme_tutorialBody}>
            {programDetails?.tutorials &&
              programDetails?.tutorials.map((tutorial, index) => (
                <div className={styles.programme_tutorialCard} key={index}>
                  <div className={styles.programme_tutorialCard_imageContainer}>
                    <img src={ProgrammeImage} />
                  </div>
                  <div className={styles.programme_tutorialCard_content}>
                    <h2>{tutorial?.title}</h2>
                      {Array.isArray(tutorial?.contents) &&
                    (<ul>
                        {tutorial?.contents.map((content, index) => <li key={index}>{content}</li>)}
                    </ul>)}
                      {tutorial?.overview && <p>{tutorial.overview}</p>}
                  </div>
                </div>
              ))}
          </div>
        </section>

        <section className={styles.programme_concepts}>
          <div className={styles.programme_conceptHead}>
            <span>Other Benefits</span>
            <h1>Other Concepts</h1>
          </div>
          <div className={styles.programme_conceptBody}>
            {Array.isArray(programDetails?.concepts) &&
              programDetails?.concepts.map((concept, index) => (
                <div
                  key={index}
                  className={
                    concept?.type === "span2" ? styles.programme_conceptCard_span2 : styles.programme_conceptCard
                  }
                >
                  <div className={styles.programme_conceptCard_imageContainer}>
                    <img src={ProgrammeImage} />
                  </div>
                  <div className={styles.programme_conceptCard_content}>
                    <h2>{concept?.title}</h2>
                    <p>{concept?.overview}</p>
                  </div>
                </div>
              ))}
          </div>
        </section>

        <section className={styles.programme_requirements}>
          <div className={styles.programme_requirementWrapper}>
            <div className={styles.programme_requirementHead}>
              <span>Requirements</span>
              <h1>What you need to have</h1>
            </div>
            <div className={styles.programme_requirementBody}>
              {Array.isArray(programDetails?.requirements) &&
                programDetails?.requirements.map((requirement, index) => (
                  <div key={index} className={styles.programme_requirementItem}>
                    <TickIcon />
                    <p>{requirement}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.programme_requirementImagesWrapper}>
            <div className={styles.programme_requirementImage}>
              <img src={RequirementImage1} />
            </div>
            {(!programDetails?.singleRequirementImage) && 
            <div className={styles.programme_requirementImage}>
              <img src={RequirementImage2} />
            </div>
            }
          </div>
        </section>

        {/* Special Virtual Page */}
        {specialVirtualPage ? (
          <section className={styles.virtualProgramMoreAbout}>
            <div className={styles.virtualProgramMoreAboutA}>
              <div>
                <h6>KICK OFF</h6>
                <h2>How to get started?</h2>
              </div>
              <div>
                <p>
                  <span>Step One:</span> Sign up for the program and make payment
                </p>
                <p>
                  <span>Step Two: </span>Your child will receive an email containing a link and guidelines to set up
                  his/her online learning account
                </p>
                <p>
                  <span>Step Three:</span> Click the link on the email to log in to your learning platform, view the
                  learning curriculum and begin learning from the comfort of your home.
                </p>
                <p>
                  We have some of the most experienced hands giving learning content which your child will benefit from.
                </p>
              </div>
            </div>
            <div className={styles.virtualProgramMoreAboutB}>
              <div>
                <h6>WHAT YOU WILL LEARN</h6>
                <h2>Our Learning Path</h2>
              </div>
              <div>
                <CodevilleLearningPathCard
                  headerText={"Frontend Development"}
                  icon={htmlTag}
                  stackList="HTML CSS JavaScript"
                  paragraphOne="The frontend end development learning path is broken down into the following 3 micro paths:"
                  paragraphTwo="Your child would be able to fully understand how the web works, and turn simple designs to responsive web pages. Your child would also understand the concept behind hosting, servers, media queries, and more. This would be completed within the space of 3 months - One month for each micropath. You can as well decide to learn just one micropath."
                />
                <CodevilleLearningPathCard
                  headerText={"Product Design"}
                  icon={figma}
                  paragraphOne="This beginners course to User Experience and Design Thinking is unlike any other e-learning experience for kids."
                  paragraphTwo="Your child will be introduced to the UX design philosophy of designing with empathy, and will also get to  solve problems like a UX designer for the very first time. They will even get to try their hand at technical UX skills such as wireframes & prototypes.
By the end of the lessons, they’ll not only have the knowledge on how to design an app, website or product, but also a certificate of completion.
"
                />
                <CodevilleLearningPathCard
                  headerText={"Graphic Design"}
                  icon={adobe}
                  paragraphOne="The graphic design learning path will be completed within the space of one month"
                  paragraphTwo="Your child would be able to express themselves through colors and hone their creative thinking. Your child would also improve how they understand visual cues and develop their creative confidence. This would be completed within the space of 1 month.
                  "
                />
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
      <div>
        {specialVirtualPage ? (
          <section className={styles.virtualMustHaves}>
            <h6>REQUIREMENTS</h6>
            <h2>Must-Haves</h2>
            <div>
              <div className={styles.virtualMustHaveItem}>
                <img src={laptopImage} alt="laptop" />
                <p>A Laptop</p>
              </div>
              <div className={styles.virtualMustHaveItem}>
                <img src={wifiImage} alt="wifi" />
                <p>Stable internet connection</p>
              </div>
              <div className={styles.virtualMustHaveItem}>
                <img src={headPhoneImage} alt="head-phone" />
                <p>A headphone</p>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
      <section className={styles.programFAQsContainer}>
        <div className={styles.programFAQsWrapper}>
          <aside className={styles.programFAQIntro}>
            <h6>faq</h6>
            <h2>Need More Help?</h2>
          </aside>

          <aside className={styles.programFAQs}>
            {programFAQs.map((faq) => (
              <CodeVilleAccordion key={`${faq.accordionQuestion}`} {...faq} customClass={styles.programFAQ} />
            ))}
          </aside>
        </div>
      </section>
      {bannerElement}
    </section>
  );
};

export { CodeVilleProgramLayout };
