import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowDown, Cancel, GenesysLogo, Hamburger, logo } from "../../assets";
import { useOnClickOutside } from "../../hooks";

import styles from "./styles.module.css";

const Header = () => {
  const [displayMobileNav, setDisplayMobileNav] = useState(false);
  const [displayDropDown, setDisplayDropDown] = useState(false);
  const [displaySmallScreenDropDown, setDisplaySmallScreenDropDown] = useState(false);
  const learnableLink = "https://learnable.genesystechhub.com";
  const outsourcinglink = "https://hublylimited.com";

  const ref = useRef();

  useOnClickOutside(ref, () => setDisplayDropDown(false));

  return (
    <div className={styles.container}>
      {/* mobile screens and small tablets */}
      <header className={styles["small-header"]}>
        <div>
          <NavLink end to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <Hamburger className={styles.hamburger} onClick={() => setDisplayMobileNav(true)} />
        {displayMobileNav ? (
          <nav>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  onClick={() => setDisplayMobileNav(false)}
                  end
                  to="/about"
                >
                  ABOUT
                </NavLink>
              </li>
              {/* Todo  cleanup outsourcing section  */}
              {/* <li>
                <NavLink onClick={() => setDisplayMobileNav(false)} to="/outsourcing">
                  OUTSOURCING
                </NavLink>
              </li> */}
              <li>
                <a href={outsourcinglink} target="_blank" rel="noreferrer">
                  OUTSOURCING
                </a>
              </li>
              <li>
                <div onClick={() => setDisplaySmallScreenDropDown(!displaySmallScreenDropDown)}>
                  <span>LEARNING</span>{" "}
                  <span>
                    <ArrowDown
                      className={styles["mobile-dropdown-icon"]}
                      style={
                        displaySmallScreenDropDown
                          ? { transform: "rotate(180deg)", transition: "all 0.3s ease-in" }
                          : {}
                      }
                    />
                  </span>
                </div>
                {displaySmallScreenDropDown ? (
                  <div className={styles["drop-down-small"]}>
                    <ul>
                      <li>
                        <a href={learnableLink} target="_blank" rel="noreferrer">
                          LEARNABLE
                        </a>
                      </li>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/codeville">
                          CODEVILLE
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/sparks">
                          SPARKS
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  onClick={() => setDisplayMobileNav(false)}
                  end
                  to="/startzone"
                >
                  STARTUP
                </NavLink>
              </li>
              {/* <li>
                <a href="http://ignite.genesystechhub.com/" rel="noReferrer" target="_blank">
                  TECH EVENT
                </a>
              </li> */}
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  onClick={() => setDisplayMobileNav(false)}
                  end
                  to="/contact"
                >
                  CONTACT
                </NavLink>
              </li>
            </ul>
            <Cancel className={styles.cancel} onClick={() => setDisplayMobileNav(false)} />
          </nav>
        ) : (
          ""
        )}
      </header>
      <header className={styles["big-header"]}>
        <div>
          <NavLink end to="/" className={styles["header-logo"]}>
            <GenesysLogo />
          </NavLink>
        </div>
        <nav>
          <ul>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/about">
                ABOUT
              </NavLink>
            </li>
            <li>
              <a href={outsourcinglink} target="_blank" rel="noreferrer">
                OUTSOURCING
              </a>
            </li>
            {/* <li>
              <NavLink to="/outsourcing">OUTSOURCING</NavLink>
            </li> */}
            <li
              className={styles["drop-down-container"]}
              onClick={() => setDisplayDropDown(!displayDropDown)}
              ref={ref}
            >
              <span>LEARNING</span>{" "}
              <span>
                <ArrowDown
                  style={displayDropDown ? { transform: "rotate(180deg)", transition: "all 0.3s ease-in" } : {}}
                />
              </span>
              {displayDropDown ? (
                <div className={styles["drop-down"]}>
                  <ul>
                    <li>
                      <a href={learnableLink} target="_blank" rel="noreferrer">
                        LEARNABLE
                      </a>
                    </li>
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/codeville/">
                        CODEVILLE
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/sparks">
                        SPARKS
                      </NavLink>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/startzone">
                STARTUP
              </NavLink>
            </li>
            {/* <li>
              <a href="http://ignite.genesystechhub.com/" rel="noReferrer" target="_blank">
                TECH EVENT
              </a>
            </li> */}
            <li>
              <NavLink className={({ isActive }) => (isActive ? styles.active : "")} end to="/contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export { Header };
