import React from "react";
import { buildAfricaImage, crookWaysImage_1, crookWaysImage_2 } from "../../../../assets";
import styles from "./styles.module.css";

const IntentionSection = () => {
  return (
    <section className={`codevilleSectionWrapper ${styles.intentionMainContainer}`}>
      <div className={styles.intentionSection}>
        <aside className={styles.intentionSectionContent}>
          <h6>The goal</h6>
          <h2>Building Africa’s Future Tech Rockstars</h2>
          <p>
            Children are fascinating, they’ve got that spark of life and are always wanting know more about everything.
            We’ve come to learn that when a child’s quest for knowledge is channeled to the right direction, that child
            becomes unstoppable in an uphill climb for knowledge and success.
          </p>
          <p>
            On the other hand, we’ve mastered the art of learning. Our methods over the years have helped us discover
            ways to take education out of the classroom. With the right blend of experimentation, incentivised learning,
            peer activities, and experienced facilitators, Codeville grooms kids who approach every problem is an
            opportunity to learn.
          </p>
        </aside>
        <aside className={styles.imageContainer}>
          <img src={buildAfricaImage} alt="child working on a project" />
        </aside>
      </div>
      <div className={styles.intentionSection}>
        <aside className={styles.imageContainer}>
          <img src={crookWaysImage_1} alt="child working on a project" />
          <img src={crookWaysImage_2} alt="child working on a project" />
        </aside>
        <aside className={styles.intentionSectionContent}>
          <h6>Vision</h6>
          <h2>Aligning Your Child’s View of The Future</h2>
          <p>
            Codeville gives your child an opportunity to see the world through a different lens, and truly imagine all
            the possibilities while getting their hands dirty in concepts like Robotics and Mixed Reality.
          </p>
          <p>
            Your child also gets a platform to express himself/herself as much as possible through a mixture of active,
            social, and scenario-based learning methodologies that fosters inclusion, understanding, and engagement.{" "}
          </p>
        </aside>
      </div>
    </section>
  );
};

export { IntentionSection };
