import React from "react";

import { learnablePathData, LearningPathSpiral } from "../../../../assets";

import styles from "./styles.module.css";
import learnableStyles from "../../styles.module.css";

import { useImageOnLoad } from "usehooks-ts";

const LearnableLearningPaths = () => {
  const { handleImageOnLoad, css } = useImageOnLoad();

  return (
    <section className={styles.container} id="learning">
      <LearningPathSpiral className={styles.spiral} />
      <div className="learnable-container">
        <div>
          <h2 className={learnableStyles["header-text"]}>Our learning paths</h2>
          <p className={learnableStyles["header-paragraph"]}>
            We aim to build an ecosystem for young techies and startup companies that create value <br /> and make a
            difference while being up to date with the new technologies.
          </p>
        </div>
        <div className={styles.grid}>
          {learnablePathData.map(({ Icon, paragraph, header, image }, index) => (
            <div key={index} className={styles["grid-card"]}>
              <div className={styles["grid-card-text-container"]}>
                <span className={styles["card-icon"]}>{<Icon />}</span>
                <h3>{header}</h3>
                <p>{paragraph}</p>
              </div>
              <div className={styles["grid-card-image-container"]}>
                {/* Small image load fast */}
                <img src={image} alt={header} style={{ ...css.thumbnail }} />
                {/* Full size image */}
                <img src={image} alt={header} onLoad={handleImageOnLoad} style={{ ...css.fullSize }} loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { LearnableLearningPaths };
