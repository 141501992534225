import React from "react";
import { useNavigate } from "react-router-dom";
import { closedAppImage } from "../../assets";
import { CodeVilleButton } from "../../components";
import styles from "./styles.module.css";

const CodeVilleClosedAppPage = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.mainContainer}>
      <aside className={styles.textContent}>
        <h1>Application is closed</h1>
        <CodeVilleButton label="Go to Home" buttonType="secondary" onClick={() => navigate("/codeville")} />
      </aside>
      <aside className={styles.imageContainer}>
        <img src={closedAppImage} alt="" />
      </aside>
    </main>
  );
};

export { CodeVilleClosedAppPage };
