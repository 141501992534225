import React, {useState} from "react";

import styles from "./styles.module.css";
import DefaultProfileImage from "./../../../../../assets/images/codeville/gallery/default-profile-image.jpg";
import DataNotFoundImage from "./../../../../../assets/images/codeville/gallery/data-not-found.png";

import graduates_2022 from "../../../../../assets/data/graduates-2022.json";
import graduates_2023 from "../../../../../assets/data/graduates-2023.json";
import { Link } from "react-router-dom";

const PastGraduates = () => {

	// Tab styles
	const active_tab = {
		background: "linear-gradient(273.75deg, #e13f29 -1.55%, #fb7a68 -1.54%, #ec1840 104.27%)",
		color: "#eeeeee",
	}

	// Data
	const graduation_data = {
		"2021": null,
		"2022": graduates_2022,
		"2023": graduates_2023,
	};

	const [graduates, setGraduates] = useState({
		year: "2023", // default to 2023
		list: graduation_data["2023"],
	});

	function fetchGraduates(year) {
		const data = graduation_data[year];
		if (data) {
			// console.log(data);
			setGraduates({
				year: year,
				list: data,
			});
		} else {
			setGraduates({
				year,
				list: null,
			});
		}
	}

	return (
		<section className={styles.pastGraduates}>
			<div className={styles.pastGraduates_header}>
				<span>The Ultimate MVP Of The Year</span>
				<h1>The Past Graduates</h1>
			</div>
			<div className={styles.pastGraduates_content}>
				<div className={styles.content_tabs}>
					<span
						style={(graduates.year === "2021") ? active_tab : {}}
						onClick={() => fetchGraduates("2021")}
					>2021</span>
					<span
						style={(graduates.year === "2022") ? active_tab : {}}
						onClick={() => fetchGraduates("2022")}
					>2022</span>
					<span
						style={(graduates.year === "2023") ? active_tab : {}}
						onClick={() => fetchGraduates("2023")}
					>2023</span>
				</div>
				{(graduates.list) ? (<>
				<div className={styles.pastGraduates_list}>
					{(window.innerWidth < 540) ? (<>
						{(graduates.list.map((graduate, index) => (
							(index < 5) &&
							<div
								key={graduate.id}
								className={styles.pastGraduates_listItem}
							>
								<div className={styles.pastGraduates_listItem_imageContainer}>
									<img
										loading="eager"
										src={graduate.image}
										onError={(evt) => {
											evt.target.src = DefaultProfileImage;
											evt.onerror = null;
										}}
									/>
								</div>
								<div className={styles.pastGraduates_listItem_contentContainer}>
									<h3>{graduate.first_name} {graduate.last_name}</h3>
									<p>{graduate.school_name ?? "School not provided"}</p>
									<span>{graduate.age ? `${graduate.age} years old` : "Age not provided"}</span>
								</div>
							</div>
						)))}
					</>) : (<>
						{(graduates.list.map(graduate => (
							<div
								key={graduate.id}
								className={styles.pastGraduates_listItem}
							>
								<div className={styles.pastGraduates_listItem_imageContainer}>
									<img
										loading="eager"
										src={graduate.image}
										onError={(evt) => {
											evt.target.src = DefaultProfileImage;
											evt.onerror = null;
										}}
									/>
								</div>
								<div className={styles.pastGraduates_listItem_contentContainer}>
									<h3>{graduate.first_name} {graduate.last_name}</h3>
									<p>{graduate.school_name ?? "School not provided"}</p>
									<span>{graduate.age ? `${graduate.age} years old` : "Age not provided"}</span>
								</div>
							</div>
						)))}
					</>)}
				</div>
				{(window.innerWidth < 540) && <Link target="_blank" to={"/codeville/gallery/past-graduates"} className={styles.pastGraduates_loadMore}>Load More Graduates</Link>}
				</>) : (<div className={styles.listNotFound}>
					<div className={styles.listNotFound_imageContainer}>
						<img loading="eager" src={DataNotFoundImage} />
					</div>
					<p>Gallery will be uploaded soon</p>
				</div>)}
			</div>
		</section>
	)
}

export { PastGraduates }