import React from "react";
import styles from "./styles.module.css";

const CodeVilleFAQTab = ({ FAQTabItems, selectedTab, setSelectedTab }) => {
  return (
    <ul className={styles.faqTabContainer}>
      {Object.keys(FAQTabItems).map((item, idx) => {
        const isActive = selectedTab === item;
        const activeStyles = isActive ? styles.activeFAQ : "";

        return (
          <li
            key={`${item} ${idx}`}
            onClick={() => setSelectedTab(item)}
            className={`${styles.faqTabItem} ${activeStyles}`}
          >
            {FAQTabItems[item].title}
          </li>
        );
      })}
    </ul>
  );
};

export { CodeVilleFAQTab };
