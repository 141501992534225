import emailjs from "@emailjs/browser";

export async function SendEmail(recipient, subject="") {
  try {
    await emailjs.send("service_uzzxfgf", "template_xnj22z9", {
      email_subject: subject,
      to_email: recipient,
    }, {
        publicKey: process.env.REACT_APP_EMAILJS_KEY
    });
    return true;
  } catch (error) {
    throw new Error("Error sending email");
  }
}
