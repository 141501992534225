import React, { useState } from "react";
import { AccMinusIcon, AccPlusIcon } from "../../../assets";
import styles from "./styles.module.css";

const CodeVilleAccordion = ({
  customClass = "",
  accordionQuestion = "How many logins can exist in one account?",
  questionAnswer = "One login per account. Another login with another device will log current one out",
}) => {
  const [isOpen, setIsopen] = useState(false);
  const answerIsAList = Array.isArray(questionAnswer);

  return (
    <section
      className={`
      ${styles.accordionContainer} 
      ${isOpen ? styles.open : ""} 
      ${customClass}
    `}
    >
      <p onClick={() => setIsopen((state) => !state)}>
        {accordionQuestion} <span className={styles.toggleIcon}>{isOpen ? <AccMinusIcon /> : <AccPlusIcon />}</span>
      </p>
      {answerIsAList ? (
        <ul
          className={`
          ${styles.accordionAnswer} 
          ${styles.accordionListAnswer} 
          ${isOpen ? styles.accordionAnswerOpen : ""}
          `}
        >
          {questionAnswer.map((question, idx) => (
            <li key={idx}>{question}</li>
          ))}
        </ul>
      ) : (
        <span className={`${styles.accordionAnswer} ${isOpen ? styles.accordionAnswerOpen : ""}`}>
          {questionAnswer}
        </span>
      )}{" "}
    </section>
  );
};

export { CodeVilleAccordion };
