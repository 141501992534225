import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Cancel } from "../../../assets";

import styles from "./styles.module.css";

const Modal = forwardRef(function Modal({ children, initialLoadState = true }, ref) {
  const [openModal, setOpenModal] = useState(initialLoadState);

  useImperativeHandle(ref, () => ({
    openModal: (value) => setOpenModal(value),
  }));

  if (!openModal) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Cancel className={styles.cancel} onClick={() => setOpenModal(false)} />
        {children}
      </div>
    </div>
  );
});

export { Modal };
