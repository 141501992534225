import React from "react";
import ReactPlayer from "react-player";
import styles from "./styles.module.css";

const CodeVilleHomeExperienceSection = () => {
  return (
    <section className={styles.experienceContainer}>
      <header className={styles.experienceHeader}>
        <h6>“The First step is often the hardest”</h6>
        <h2>The Codeville Experience</h2>
        <p>
          Tailored by humans who have experienced the best of both worlds in teaching kids and navigating technology,
          the Codeville experience would give your child a hands-on introduction to software development, design,
          robotics, machine learning, and more. All in one wholesome holiday.
        </p>
      </header>

      <aside className={styles.experienceVideo}>
        <ReactPlayer
          style={{ width: "100%" }}
          width="100%"
          height="528.16px"
          url="https://player.vimeo.com/video/566112201?app_id=122963&referrer=https%3A%2F%2Fwww.genesystechhub.com%2F"
        />
      </aside>
    </section>
  );
};

export { CodeVilleHomeExperienceSection };
