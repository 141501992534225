import React from "react";
import { HalfGridCard } from "../aboutUs/components";
import { ContactUsBox } from "../../components";
import { InvestmentImg } from "../../assets";
import { partshop, greenage, uwe, scuudu, lawyerpp } from "../../assets/images";
import styles from "./styles.module.css";
import { InvestmentCard } from "../investment/components";

const Investment = () => {
  const ourPartners = [
    {
      icon: partshop,
      linkName: "www.partzshop.com",
      link: "//www.partzshop.com",
      text: "PartzShop is giving automobile owners in West Africa access to premium quality spare parts for their vehicles.",
    },
    {
      icon: greenage,
      linkName: "www.greenagetech.com",
      link: "//www.greenagetech.com",
      text: "Greenage is solving Nigeria’s energy problems by manufacturing smart solar-powered inverters.",
    },
    {
      icon: uwe,
      linkName: "www.uwe.ng",
      link: "//www.uwe.ng",
      text: "Uwe is disrupting the world of fashion by giving individuals an opportunity to be their own fashion designer, through tech.",
    },
    {
      icon: scuudu,
      linkName: "www.scuudu.com",
      link: "//www.scuudu.com",
      text: "Scuudu Labs is infusing futuristic technologies like AI, ML, and MR in everyday solutions for individuals.",
    },
    {
      icon: lawyerpp,
      linkName: "www.lawyerpp.com",
      link: "//www.lawyerpp.com",
      text: "Lawyerpp is giving the average Nigerian access to legal representation and processes all through one app.",
    },
  ];
  return (
    <>
      <section id="about">
        <HalfGridCard
          smallHeader="WHAT WE DO"
          LargeHeader="Get direct access to investment funding and resources"
          paragraph="Limited access to funding is one of the major reasons why 
                    a lot startups fail to survive after the first year. We’re on a mission 
                    to make these resources abundant, one startup at a time."
        />
        <div className={styles.InvestmentWrapper}>
          <img className={styles.investmentImg} src={InvestmentImg} alt="investment_img" loading="lazy" />
        </div>
      </section>
      <section>
        <div className={styles.invContainerFluid}>
          <div className={styles.invCol}>
            <div className={styles.invHeadingWrapper}>
              <h6 className={styles.smallHeading}>PEOPLE WE’VE WORKED WITH</h6>
              <h4 className={styles.largeHeading}>Our Portfolio</h4>
            </div>
            <p className={styles.invFullGridCardParagraph1}>
              Like our people, the startups we’ve worked with are outliers, and we are proud to have contributed towards
              their growth. Here are some of our favorite ones
            </p>
          </div>
        </div>
      </section>
      <section className={styles.investmentContainer}>
        <div className={styles.investmentRow}>
          {ourPartners?.map((ele, ind) => {
            return (
              <InvestmentCard
                key={ind}
                img={ele?.icon}
                paragraph={ele?.text}
                link={ele?.link}
                linkName={ele?.linkName}
              />
            );
          })}
        </div>
      </section>
      <section>
        <ContactUsBox
          header="Ready for investment Opportunity? "
          paragraph="If you are thinking in the line of positive growth, development, and learning for your business,
                    we’d like to hear from you. "
        />
      </section>
    </>
  );
};
export { Investment };
