import React from "react";
import { Footer, Header } from "../../components";
import styles from "./styles.module.css";

const MainLayout = ({ children }) => {
  return (
    <div className={"container"}>
      <Header />
      <div className={styles.children}>{children}</div>
      <Footer />
    </div>
  );
};

export { MainLayout };
