import React, { useMemo } from "react";
import { progVirtAbtImage } from "../../../assets";
import { CODEVILLE_APP_LINKS } from "../../../config";
import { CodeVilleProgramLayout } from "../../../layouts";

const CodeVilleJuniorVirtualPage = () => {
  // Page FAQs
  const programFAQs = useMemo(
    () => [
      {
        accordionQuestion: "Who is eligible for the Codeville Virtual Camp?",
        questionAnswer:
          "Codeville Virtual Class is for kids between the ages of 9 and 16. It is designed to focus a child's learning process on a chosen learning path for the space of a month.",
      },
      {
        accordionQuestion: "What are the requirements?",
        questionAnswer: [
          "Must own a laptop",
          "Must have a stable internet connection",
          "Must have headphones and a microphone",
        ],
      },
      {
        accordionQuestion: "Where is the facility?",
        questionAnswer: "It's a Virtual program and there is no physical location.",
      },
      {
        accordionQuestion: "What time does classes start?",
        questionAnswer:
          "Since it’s a virtual program, the classes are released each week and can be watched at convenience between when it’s released and the next class. ",
      },
      {
        accordionQuestion: "Can the Codeville program inform my career choice?",
        questionAnswer:
          "Yes! This program is designed to specialize your learning. It gives you a deep knowledge of the learning path you have chosen, provides an opportunity to try out different aspects of technology and helps you specialize in the aspect you are most interested in.",
      },
      {
        accordionQuestion: "Does the child need any IT skills to enroll?",
        questionAnswer: "Yes, the child needs to know how to navigate apps on a computer.",
      },
      {
        accordionQuestion: "How do we join?",
        questionAnswer:
          "To be part of the Codeville program, please visit the application link, complete the application and make payment. Then send an evidence of payment to codeville@genesystechhub.com",
      },
      {
        accordionQuestion: "Can I reach out to anyone for further information?",
        questionAnswer: "Yes, please call 07037153284 Or 08124487288 or email codeville@genesystechhub.com",
      },
    ],
    [],
  );

  return (
    <CodeVilleProgramLayout
      programFAQs={programFAQs}
      programTitle="Codeville Virtual Camp"
      programDesc="In just 1 month, your child can be on his/her way to becoming a tech guru by signing up to learn at the Codeville Kids Virtual Camp. From the basics of the internet and how the web works, to how to design and build standard web pages. Your child can learn it all, all from the comfort of home. "
      porgramAboutImage={progVirtAbtImage}
      aboutProgramText={
        <React.Fragment>
          <p>
            The Codeville Virtual Camp has 2 cycles every year, with every cycle having 3 months each. This makes it
            possible for the child to learn 3 new tech skills within the 3 months or learn just one new tech skill in
            one month. This is because Codeville Virtual Camp runs on a monthly subscription and each course is designed
            to run for one month.
          </p>
          <p>
            Also, every child gets the chance to choose which of the learning paths he/she would like to learn for any
            learning month he/she is a part of. At the end of each learning month, your child will go through a test to
            ascertain his/her level of proficiency and then be awarded the Codeville Genius Certificate of completion.
          </p>
        </React.Fragment>
      }
      aboutProgSubTitle="SIT BACK AND LEARN"
      applyLink={CODEVILLE_APP_LINKS.juniorAcademyVirtual || CODEVILLE_APP_LINKS.generalLink}
      specialVirtualPage={true}
    />
  );
};

export { CodeVilleJuniorVirtualPage };
