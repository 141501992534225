import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  CodeVilleLayout,
  LearnableLayout,
  MainLayout,
  SparksLayout,
  StartzoneLayout,
  OutsourcingLayout,
} from "../layouts";
import { codeVilleRoutes, learnableRoute, mainRoute, sparksRoute, startzoneRoute, outsourcingRoute } from "./routes";
import { ScrollToTop } from "./utils";

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Main routes */}
        {mainRoute.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<MainLayout>{routeProps.element}</MainLayout>}
          />
        ))}

        {/* Codeville routes */}
        {codeVilleRoutes.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<CodeVilleLayout>{routeProps.element}</CodeVilleLayout>}
          />
        ))}

        {/* Learnable moved to new repo */}

        {/* Learnable routes */}
        {/* {learnableRoute.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<LearnableLayout>{routeProps.element}</LearnableLayout>}
          />
        ))} */}

        {/* Sparks routes */}
        {sparksRoute.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<SparksLayout>{routeProps.element}</SparksLayout>}
          />
        ))}

        {/* Startzone routes */}
        {startzoneRoute.map((routeProps, index) => (
          <Route
            key={`${routeProps.path} ${index}`}
            caseSensitive={true}
            {...routeProps}
            element={<StartzoneLayout>{routeProps.element}</StartzoneLayout>}
          />
        ))}

        {/* Todo  Outsourcing needs to be cleanedup */}

        {/* Outsourcing routes */}
        {/* {outsourcingRoute.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<OutsourcingLayout>{routeProps.element}</OutsourcingLayout>}
          />
        ))} */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
