import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, startzoneImg1, startzoneImg2, whatWeAreIntoImg1, whatWeAreIntoImg2 } from "../../assets";
import { Button, ContactUsBox, FaqCard, PageHeader } from "../../components";
import { WhatWeAreIntoCard } from "./components";

import styles from "./styles.module.css";

const StartZone = () => {
  const navigation = useNavigate();

  return (
    <div className={styles.container}>
      <section>
        <PageHeader
          smallHeader="Who we are"
          header="Build your business from the ground up"
          paragraph={
            "Every business has to draw its own blueprint to success following standard principles. We’d like to help you draw yours, one milestone at a time."
          }
        />
      </section>
      <section>
        <div>
          <div>
            <img src={startzoneImg1} alt="start-zone-img-1" />
          </div>
          <div>
            <img src={startzoneImg2} alt="start-zone-img-2" />
          </div>
        </div>
      </section>
      <div id="about"></div>
      <section>
        <div>
          <PageHeader smallHeader="Our Identity" header="What We Stand For" />
          <div className="subcontainer">
            <p>
              Since inception(2017), we’ve been in the business of rapidly scaling startups within the region by
              offering all the support (funding, resources, knowledge, access) they need, and climbing up the stairs of
              success with them, one milestone at a time.
            </p>
            <p>
              We believe that success in business can only be achieved when the right blend of strategy, team, and
              execution methods are applied. Our mission is to greatly contribute to the growth of as many businesses
              that are willing to learn the ropes, put in the work, and trust the process, with us.
            </p>
          </div>
        </div>
      </section>
      <section>
        <PageHeader smallHeader="Our Aims" header="What We Are Into" />
        <div className={`${styles["wWAinto-container"]} subcontainer`}>
          <WhatWeAreIntoCard
            header="Business Incubation"
            paragraph="Learn all you need for rapid growth and development."
            link="/startzone/business-incubation"
            background={whatWeAreIntoImg1}
          />
          <WhatWeAreIntoCard
            header="Investment"
            paragraph="Get funding to fuel your cause as a young business."
            link="/startzone/investment"
            background={whatWeAreIntoImg2}
          />
        </div>
      </section>
      <section>
        <ContactUsBox
          smallHeader="TAKE THE BIG LEAP"
          header={"Ready to grow your business? "}
          paragraph={
            "If you are thinking in the line of positive growth, development, and learning, we’d like to hear what you have to say."
          }
        />
      </section>
      <section id="faq">
        <div className={styles["faq-container"]}>
          <div>
            <PageHeader smallHeader="faq" header="Need More Help?" />
            <div className={`subcontainer`}>
              <Button onClick={() => navigation("/contact")} className={styles["contact-button-a"]}>
                <span>CONTACT US </span>
                <span style={{ marginLeft: "10px" }}>
                  <ArrowRight />
                </span>
              </Button>
            </div>
          </div>
          <div className="subcontainer">
            <FaqCard header="Do we need to pay to join Startzone?" paragraph="No, Startzone is completely free." />
            <FaqCard
              header="What does my business gain from joining Startzone?"
              paragraph="Startzone will provide your business with access to practical knowledge from mentors who have spent years in the 
              industry. You will learn every thing you need to grow, scale, and position your business to get resources and
              funding."
            />
            <FaqCard
              header="Will Startzone give my business access to funding?"
              paragraph="Yes,  pending on a number of more detailed processes, Startzone ‘s  main goal is to get your business 
              set up to receive funding from various opportunities."
            />
            <FaqCard
              header="Do I need to be in a particular city to join Startzone?"
              paragraph="No, you can experience the journey from any geographical location. All you need is access to the 
              internet."
            />
            <FaqCard
              header="Is the Program just for Nigerians?"
              paragraph="No, Startzone is designed to accommodate businesses from all across Africa."
            />
            <Button onClick={() => navigation("/contact")} className={styles["contact-button-b"]}>
              <span>CONTACT US </span>
              <span style={{ marginLeft: "10px" }}>
                <ArrowRight />
              </span>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export { StartZone };
