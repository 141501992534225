import React from "react";
import { RoboticsIcon } from "../../assets";
import { CodeVilleAccordion, CodeVilleBasicCard, CodeVilleProgramCard, CodeVilleReviewCard } from "../../components";
import styles from "./styles.module.css";

const CodeVilleComponents = () => {
  return (
    <section className={styles.codeVilleCompWrapper}>
      <h2>Accordion</h2>
      <section style={{ backgroundColor: "#FAFAFA", padding: "20px", display: "flex" }}>
        <CodeVilleAccordion />
      </section>
      <h2>Program cards</h2>
      <section style={{ backgroundColor: "#FAFAFA", padding: "20px", display: "flex" }}>
        <CodeVilleProgramCard icon={<RoboticsIcon />} programType="entry" />
        <CodeVilleProgramCard icon={<RoboticsIcon />} programType="junior" />
        <CodeVilleProgramCard applyLink="https://forms.gle/JJJcMxGvdVUo8fBeA" icon={<RoboticsIcon />} programType="advanced" />
      </section>
      <h2>Codeville basic card</h2>
      <CodeVilleBasicCard icon={<RoboticsIcon />} />
      <h2>Codeville review card</h2>
      <CodeVilleReviewCard />
    </section>
  );
};

export { CodeVilleComponents };
